import './App.css';
import BasicTable from './BasicTable';
import { useEffect } from 'react';
import { useState } from 'react';

function App() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    // Function to update isSmallScreen state based on window width
    const updateScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust threshold as needed
    };

    // Add event listener to update screen size on window resize
    window.addEventListener('resize', updateScreenSize);

    // Initial call to set screen size on component mount
    updateScreenSize();

    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);


  return (
    <div className="App" style={{maxWidth: "45em", paddingTop: isSmallScreen ? "" : "2em", paddingBottom: "2em", margin: "0 auto"}}>
      <BasicTable />
    </div>
  );
}

export default App;
