function getData() {
    return [
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Chubb Ltd Shs",
            "IndexWeight": "0.0014793079691123516"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Prologis Inc Common Stock",
            "IndexWeight": "0.0019366428627074364"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AT&T, Inc.",
            "IndexWeight": "0.0019289835585670357"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Abbott Laboratories Common Stock",
            "IndexWeight": "0.004378158873865671"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Accenture PLC Share A",
            "IndexWeight": "0.003674667229078655"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Adobe Inc Shs",
            "IndexWeight": "0.004686427110865804"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Advanced Micro Devices",
            "IndexWeight": "0.0057414377740424676"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Aflac, Inc.",
            "IndexWeight": "0.0007465812966874191"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Agilent Technologies Inc Common Stock",
            "IndexWeight": "0.0009471322357640142"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Air Products and Chemicals, Inc.",
            "IndexWeight": "0.0010171595403378065"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Akamai Technologies, Inc.",
            "IndexWeight": "0.00029871779273478417"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Alexandria Real Estate Equities, Inc.",
            "IndexWeight": "0.00032747476951317526"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Align Technology, Inc.",
            "IndexWeight": "0.0005011384833067611"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Allstate Corporation",
            "IndexWeight": "0.0006128228377164284"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Alnylam Pharmaceuticals, Inc.",
            "IndexWeight": "0.0005491570322019943"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Amazon.com, Inc.",
            "IndexWeight": "0.0305269105628062"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "American Electric Power Inc. Common Stock",
            "IndexWeight": "0.0007761358237576407"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "American Express Company",
            "IndexWeight": "0.0018638153294987187"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "American Financial Group, Inc.",
            "IndexWeight": "0.00014073925921211604"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "American International Group Inc Common Stock",
            "IndexWeight": "0.0007745352568544926"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "American Tower Corporation A",
            "IndexWeight": "0.0016327068502257067"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ameriprise Financial, Inc.",
            "IndexWeight": "0.000653071327827799"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cencora Inc",
            "IndexWeight": "0.00044919424114526287"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AMETEK Inc Shs",
            "IndexWeight": "0.000845642623266738"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Amgen, Inc.",
            "IndexWeight": "0.0036840055248549626"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Amphenol Corporation",
            "IndexWeight": "0.0013710445989577509"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Analog Devices, Inc.",
            "IndexWeight": "0.002410483343123171"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Annaly Capital Management, Inc.",
            "IndexWeight": "0.00016419459698433414"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ansys, Inc.",
            "IndexWeight": "0.0005522905082111906"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Aon plc Class A Shs",
            "IndexWeight": "0.0010008570616129682"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Apple Inc",
            "IndexWeight": "0.06786585037804924"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Applied Materials",
            "IndexWeight": "0.0033928763739138248"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Arch Capital Group, Ltd.",
            "IndexWeight": "0.00048656510036118567"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Archer-Daniels Midland Co Common Stock",
            "IndexWeight": "0.00032627611154328054"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Arthur J. Gallagher & Co.",
            "IndexWeight": "0.0008337073915778142"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Aspen Technology Inc Common Stock",
            "IndexWeight": "0.0000998663507208083"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AutoZone Inc",
            "IndexWeight": "0.000960745470564823"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Autodesk, Inc.",
            "IndexWeight": "0.0011431302003557188"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Automatic Data Processing",
            "IndexWeight": "0.0015944165639409974"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AvalonBay Communities, Inc.",
            "IndexWeight": "0.000413000537037667"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Avery Dennison Corp.",
            "IndexWeight": "0.00023281885546147543"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Truist Financial Corp",
            "IndexWeight": "0.0008047641534146128"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ball Corporation",
            "IndexWeight": "0.0002585987195470851"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bank of America Corporation Common Stock",
            "IndexWeight": "0.0038762896165126484"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bank of New York Mellon Corporation",
            "IndexWeight": "0.0006837947037018973"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Baxter International Inc.",
            "IndexWeight": "0.00041283570033842827"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Becton Dickinson & Co",
            "IndexWeight": "0.0016038543108204827"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Best Buy Co., Inc.",
            "IndexWeight": "0.00007903840707845411"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bio-Rad Laboratories Inc.Class A Shs",
            "IndexWeight": "0.0001595523986062167"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Biogen Inc Shs",
            "IndexWeight": "0.0009121730977361303"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Biomarin Pharmaceutical, Inc.",
            "IndexWeight": "0.00042901630127964663"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Blackrock, Inc.",
            "IndexWeight": "0.001979043838822158"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "BorgWarner Inc",
            "IndexWeight": "0.00015553781257874526"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Boston Properties, Inc.",
            "IndexWeight": "0.0002077086499047185"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Boston Scientific Corporation",
            "IndexWeight": "0.0020845918355888488"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bristol-Myers Squibb Company",
            "IndexWeight": "0.00253370335934372"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Brown & Brown, Inc.",
            "IndexWeight": "0.0002822744228339711"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Brown-Forman Corporation B",
            "IndexWeight": "0.0004621703370734828"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bunge Global SA",
            "IndexWeight": "0.00000259051737804102"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CBRE Group Inc",
            "IndexWeight": "0.00001224855761826782"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CF Industries Holdings Inc Common Stock",
            "IndexWeight": "0.00000256007762603558"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "C.H. Robinson Worldwide Inc Shs",
            "IndexWeight": "0.00007184992226557978"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CVS Health Corp Shs",
            "IndexWeight": "0.0015172890169856275"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cadence Design Systems",
            "IndexWeight": "0.0013188780140310475"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Camden Property Trust",
            "IndexWeight": "0.00017023462473811723"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Campbell Soup Company",
            "IndexWeight": "0.00013270693617905814"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Capital One Financial Corporation",
            "IndexWeight": "0.0007945140282440981"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CarMax, Inc.",
            "IndexWeight": "0.00025718194358665195"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cardinal Health, Inc.",
            "IndexWeight": "0.0005493103890371394"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Carnival Corporation",
            "IndexWeight": "0.00023500407556214161"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Caterpillar Inc.",
            "IndexWeight": "0.00002477325239074368"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Celanese Corp Ordinary Shares A",
            "IndexWeight": "0.0003272437829108573"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Centene Corporation",
            "IndexWeight": "0.0006359108322647665"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fidelity National Information Services, Inc.",
            "IndexWeight": "0.0005907881045928776"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Charles River Laboratories International Inc Common Stock",
            "IndexWeight": "0.00018449628747715734"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Charles Schwab Corp Common Stock",
            "IndexWeight": "0.0016410427507891569"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CME Group A",
            "IndexWeight": "0.0012985387074892153"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Church & Dwight Company, Inc.",
            "IndexWeight": "0.0005836984655695644"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Cigna Group",
            "IndexWeight": "0.001437023710059808"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cincinnati Financial Corporation",
            "IndexWeight": "0.00027294673482064675"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cintas Corporation",
            "IndexWeight": "0.0011426960926990144"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cisco Systems, Inc.",
            "IndexWeight": "0.0046021369655677115"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Citigroup Inc",
            "IndexWeight": "0.0015948444578168474"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Clorox Company",
            "IndexWeight": "0.0004215759606534508"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CoStar Group, Inc.",
            "IndexWeight": "0.0005654315135445498"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Coca-Cola Company",
            "IndexWeight": "0.004696090301543548"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cognizant Technology Solutions Corporation A",
            "IndexWeight": "0.0006206450778021827"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Colgate-Palmolive Company",
            "IndexWeight": "0.000956311394924003"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Comcast Corporation A",
            "IndexWeight": "0.002856380614269019"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Conagra Brands Inc Shs",
            "IndexWeight": "0.00016981273616009562"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Consolidated Edison Company",
            "IndexWeight": "0.0009349727991051105"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Constellation Brands, Inc. A",
            "IndexWeight": "0.000394901847563806"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cooper Companies",
            "IndexWeight": "0.0004484870624627667"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Copart, Inc.",
            "IndexWeight": "0.0007194567851740992"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Corning Inc.",
            "IndexWeight": "0.0005541087733575467"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Costco Wholesale Corporation",
            "IndexWeight": "0.006111535205644886"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Crown Castle Inc",
            "IndexWeight": "0.0008396476270241932"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Crown Holdings Inc Ordinary Shares",
            "IndexWeight": "0.00014158087194193517"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cummins Inc",
            "IndexWeight": "0.00000531823876460378"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "D.R. Horton Inc Shs",
            "IndexWeight": "0.0011742450446258431"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Danaher Corporation",
            "IndexWeight": "0.002620727696463228"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Darden Restaurants, Inc.",
            "IndexWeight": "0.00027721636230428434"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Deere & Company",
            "IndexWeight": "0.0014394863226554686"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "DexCom, Inc.",
            "IndexWeight": "0.0011352012422307788"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Digital Realty Trust, Inc.",
            "IndexWeight": "0.0010522997776790082"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dollar Tree Inc Shs",
            "IndexWeight": "0.0005312963057643719"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Domino's Pizza, Inc.",
            "IndexWeight": "0.00023058746032816665"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dover Corporation",
            "IndexWeight": "0.0002561216696408802"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "DuPont de Nemours Inc Regs Shs",
            "IndexWeight": "0.0005315209261654586"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "East West Bancorp Inc Common Stock",
            "IndexWeight": "0.00016019111607337458"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Eastman Chemical Company",
            "IndexWeight": "0.00004147094642844495"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Eaton Corporation PLC Common Stock",
            "IndexWeight": "0.000825234099206143"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Edison International",
            "IndexWeight": "0.0012905202742065885"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Edwards Lifesciences Corporation",
            "IndexWeight": "0.0010869093771677873"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Electronic Arts, Inc.",
            "IndexWeight": "0.0005648417649881989"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Eli Lilly & Co",
            "IndexWeight": "0.012117074137803224"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Entegris, Inc.",
            "IndexWeight": "0.0004077978138563429"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Entergy Corporation",
            "IndexWeight": "0.0004160339415046105"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Equifax, Inc.",
            "IndexWeight": "0.0004969463869365795"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Equinix Inc Common Stock",
            "IndexWeight": "0.001238262196102038"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Equity Lifestyle Properties, Inc.",
            "IndexWeight": "0.00020004603956325247"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Equity Residential",
            "IndexWeight": "0.00037194690632762494"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Essex Property Trust Inc",
            "IndexWeight": "0.00024379771681826105"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Estee Lauder Companies A",
            "IndexWeight": "0.0007703676319765662"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Everest Group Ltd",
            "IndexWeight": "0.0002674062501129031"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Exact Sciences Corporation",
            "IndexWeight": "0.00022072337945725921"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Exelon Corporation",
            "IndexWeight": "0.0006106036032930319"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Expedia Group Inc Shs",
            "IndexWeight": "0.00033267128508750687"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Expeditors International of Washington, Inc.",
            "IndexWeight": "0.0007212699097723372"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Extra Space Storage, Inc.",
            "IndexWeight": "0.0005242778497059613"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "F5 Inc",
            "IndexWeight": "0.00017636024345647183"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NextEra Energy Inc Common Stock",
            "IndexWeight": "0.005623882750341627"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "FactSet Research Systems, Inc.",
            "IndexWeight": "0.00031133225434665674"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fair Isaac, Inc.",
            "IndexWeight": "0.0004715560238874475"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fastenal Company",
            "IndexWeight": "0.0007787053646779279"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "FedEx Corporation",
            "IndexWeight": "0.0018816410218202148"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Federal Realty Investment Trust",
            "IndexWeight": "0.00013345564311340578"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fidelity National Financial Inc.",
            "IndexWeight": "0.00021392670546216825"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fifth Third Bancorp",
            "IndexWeight": "0.0003497164978712289"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "First Citizens Bancshares, Inc. A",
            "IndexWeight": "0.0002932336772316939"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fiserv, Inc.",
            "IndexWeight": "0.001218906308918494"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Flex Ltd Shs",
            "IndexWeight": "0.00000223086849492965"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ford Motor Company",
            "IndexWeight": "0.0001810513297325371"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Franklin Resources",
            "IndexWeight": "0.00014289781512508663"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Freeport-McMoRan Inc Shs",
            "IndexWeight": "0.0013713379218796566"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Garmin Ltd Namen-Akt",
            "IndexWeight": "0.00047063335816475966"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Gartner, Inc. A",
            "IndexWeight": "0.0005658187058234572"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "General Electric Co Common Stock",
            "IndexWeight": "0.00002285081938738811"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "General Mills, Inc.",
            "IndexWeight": "0.0006382112152058841"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Genuine Parts Company",
            "IndexWeight": "0.0004061433103992303"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Gilead Sciences, Inc.",
            "IndexWeight": "0.002364744125813381"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Global Payments, Inc.",
            "IndexWeight": "0.0004981705662480694"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Goldman Sachs Group Inc",
            "IndexWeight": "0.002116749920848357"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Alphabet Inc Class A",
            "IndexWeight": "0.0140801768401094"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Monster Beverage Corp Shs",
            "IndexWeight": "0.001045392228715195"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hartford Financial Services Group, Inc.",
            "IndexWeight": "0.00037340136712153753"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Healthpeak Properties Inc",
            "IndexWeight": "0.00020579394962297584"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Welltower Inc shs",
            "IndexWeight": "0.0010311756312678044"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Heico Corporation",
            "IndexWeight": "0.00018056797662817126"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Henry Schein, Inc.",
            "IndexWeight": "0.00019032914755788717"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Hershey Company",
            "IndexWeight": "0.000604302510595828"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "HP Inc Common Stock",
            "IndexWeight": "0.0003144081145159261"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hologic, Inc.",
            "IndexWeight": "0.00042211286114061383"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Home Depot, Inc.",
            "IndexWeight": "0.007251551575674955"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hormel Foods Corporation",
            "IndexWeight": "0.00011146700795357865"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Host Hotels & Resorts, Inc.",
            "IndexWeight": "0.00025944348558333145"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hubbell, Inc shs",
            "IndexWeight": "0.0003786686126287192"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Humana",
            "IndexWeight": "0.0008803402933460862"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Huntington Bancshares, Inc.",
            "IndexWeight": "0.0002840971723898384"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Match Group Inc",
            "IndexWeight": "0.00015814662177435543"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "International Business Machines Corp",
            "IndexWeight": "0.00247208105571491"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Idex Corporation",
            "IndexWeight": "0.0003771111718183671"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "IDEXX Laboratories Inc",
            "IndexWeight": "0.0006807786413687436"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Illinois Tool Works, Inc.",
            "IndexWeight": "0.0018562577667051322"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Illumina, Inc.",
            "IndexWeight": "0.0003268119818607502"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Incyte Corporation",
            "IndexWeight": "0.0002790917625981167"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Trane Technologies PLC Share A",
            "IndexWeight": "0.0013667940476491452"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Intel Corporation Common Stock",
            "IndexWeight": "0.00493537473896717"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Intercontinental Exchange Inc Common Stock",
            "IndexWeight": "0.0012423629449865592"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "International Flavors & Fragrances Inc Shs",
            "IndexWeight": "0.0003766806911201251"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "International Paper Co.",
            "IndexWeight": "0.00004218144650838872"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Interpublic Group of Companies",
            "IndexWeight": "0.0002032580107884679"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Intuit Corp Common Stock",
            "IndexWeight": "0.0028330500805595735"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Intuitive Surgical, Inc.",
            "IndexWeight": "0.00276059408824854"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Iron Mountain, Inc.",
            "IndexWeight": "0.0003315979736754045"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "J.B. Hunt Transport Services, Inc.",
            "IndexWeight": "0.0005234877434139829"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "J.M. Smucker Co.",
            "IndexWeight": "0.00018772091170932216"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "JPMorgan Chase & Co.",
            "IndexWeight": "0.008259882641897472"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Jack Henry & Associates, Inc Common Stock",
            "IndexWeight": "0.0001919254082788147"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Johnson & Johnson",
            "IndexWeight": "0.00878087266837116"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Johnson Controls International PLC Ords",
            "IndexWeight": "0.00002899784962635889"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Juniper Networks Inc Common Stock",
            "IndexWeight": "0.00016428781276816543"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "KLA Corp",
            "IndexWeight": "0.0019126076466383034"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kellanova Co",
            "IndexWeight": "0.0002658311897859291"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "KeyCorp",
            "IndexWeight": "0.00021626815386310852"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kimberly-Clark Corporation",
            "IndexWeight": "0.000752545484233131"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kimco Realty Corporation",
            "IndexWeight": "0.00021188678691099283"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mondelez International Inc Common Stock A",
            "IndexWeight": "0.001973057159275759"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kroger Company",
            "IndexWeight": "0.0005984336825274984"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "LKQ Corporation",
            "IndexWeight": "0.00030226435328861945"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Laboratory Corporation of America Holdings",
            "IndexWeight": "0.0003182523459506146"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lam Research Corporation",
            "IndexWeight": "0.0024525261061723778"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lennar Corporation",
            "IndexWeight": "0.0009105382191453727"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lennox International, Inc.",
            "IndexWeight": "0.00035437213183808276"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Live Nation Entertainment Inc Common Stock",
            "IndexWeight": "0.00022531934956101905"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lowe's Companies Inc.",
            "IndexWeight": "0.000787440230320703"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "M & T Bank Corporation",
            "IndexWeight": "0.0003748733975183236"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Markel Group Inc",
            "IndexWeight": "0.0002977725158334161"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "MarketAxess Holdings, Inc.",
            "IndexWeight": "0.0001706435174951551"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Marriott International Inc Shs A",
            "IndexWeight": "0.0007544112458192061"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Marsh & McLennan Companies",
            "IndexWeight": "0.0016051558749139066"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Martin Marietta Materials Inc",
            "IndexWeight": "0.0005040013310965829"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Marvell Technology Inc",
            "IndexWeight": "0.0013483315515017585"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Masco Corporation",
            "IndexWeight": "0.00030019043703809743"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "McCormick & Company, Inc.",
            "IndexWeight": "0.00035396611425586973"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "McDonald's Corporation",
            "IndexWeight": "0.0028878123571450163"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "S&P Global Inc",
            "IndexWeight": "0.002332818251168312"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "McKesson, Inc.",
            "IndexWeight": "0.00022522134348399467"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Medtronic PLC Shs",
            "IndexWeight": "0.002679324926443675"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Merck & Co Inc Common Stock",
            "IndexWeight": "0.006422584532038096"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Metropolitan Life Insurance Company",
            "IndexWeight": "0.0006926172766859614"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mettler-Toledo International, Inc.",
            "IndexWeight": "0.00044172743517411646"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Microchip Technology Inc Common Stock",
            "IndexWeight": "0.0012039421448923982"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Micron Technology, Inc.",
            "IndexWeight": "0.00245495049528589"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Microsoft Corp Common Stock",
            "IndexWeight": "0.04811374881913455"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mid-America Apartment Communities Inc",
            "IndexWeight": "0.00025631696560680483"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Molina Healthcare, Inc.",
            "IndexWeight": "0.0003250890249909844"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Molson Coors Beverage Co",
            "IndexWeight": "0.00021098494532580524"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Monolithic Power Systems, Inc.",
            "IndexWeight": "0.0007058750002796931"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Moody's Corporation",
            "IndexWeight": "0.0010167428957142846"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Morgan Stanley Common Stock",
            "IndexWeight": "0.001988587375947966"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Motorola Solutions Inc Common Stock",
            "IndexWeight": "0.0012500645490060149"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Viatris Inc Shs",
            "IndexWeight": "0.0002683701099132953"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NRG Energy, Inc.",
            "IndexWeight": "0.0001325163819253012"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NVIDIA Corporation",
            "IndexWeight": "0.029716943484516617"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NVR, Inc.",
            "IndexWeight": "0.00053951524729946"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nasdaq Inc Shares",
            "IndexWeight": "0.00039086036947401576"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Netflix, Inc.",
            "IndexWeight": "0.0037861347278671975"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NetApp, Inc.",
            "IndexWeight": "0.00031547615600283416"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Neurocrine Biosciences, Inc.",
            "IndexWeight": "0.00030908529145157166"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Newmont Corp Ordinary Shares",
            "IndexWeight": "0.000975403006290143"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nike, Inc. B",
            "IndexWeight": "0.002910097080469928"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Eversource Energy",
            "IndexWeight": "0.0008556755541643367"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Northern Trust Corporation",
            "IndexWeight": "0.0002882306693022664"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nucor Corp.",
            "IndexWeight": "0.0006446607112919806"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "O'Reilly Automotive, Inc.",
            "IndexWeight": "0.0012246721818535776"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ON Semiconductor Corporation",
            "IndexWeight": "0.0007858299020953051"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Old Dominion Freight Line Inc Ordinary Shares",
            "IndexWeight": "0.0015303839744656561"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Omnicom Group, Inc.",
            "IndexWeight": "0.0002733127273629751"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Oracle Corp",
            "IndexWeight": "0.0029204482111592"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "PACCAR, Inc.",
            "IndexWeight": "0.0003091459432730146"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "PG & E Corporation",
            "IndexWeight": "0.0013444815345958932"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "PNC Financial Services Group Inc",
            "IndexWeight": "0.0009960302268737177"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "PPG Industries, Inc.",
            "IndexWeight": "0.0007851018634759576"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Packaging Corporation of America",
            "IndexWeight": "0.00026214196852917203"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "PTC Inc Common Stock",
            "IndexWeight": "0.00034556592507421974"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Parker Hannifin Corporation",
            "IndexWeight": "0.0013544401047487424"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Paychex, Inc.",
            "IndexWeight": "0.0006367254974316052"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "PepsiCo Inc Common Stock",
            "IndexWeight": "0.004647034165182656"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Revvity Inc",
            "IndexWeight": "0.00022112554025862172"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Pfizer Inc.",
            "IndexWeight": "0.004130825292894038"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Linde PLC",
            "IndexWeight": "0.002088748006770056"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Booking Holdings Inc Shs",
            "IndexWeight": "0.001988199997725355"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Principal Financial Group Inc",
            "IndexWeight": "0.00031093450716299353"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Procter & Gamble Company",
            "IndexWeight": "0.008614804006057724"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Progressive Corporation",
            "IndexWeight": "0.0015921356885833687"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Prudential Financial, Inc.",
            "IndexWeight": "0.0006258210674844455"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Public Service Enterprise Group",
            "IndexWeight": "0.0011094361162646742"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Public Storage Common Stock",
            "IndexWeight": "0.000778867401300379"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "PulteGroup Inc Common Stock",
            "IndexWeight": "0.00037646650809076314"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Qualcomm, Inc.",
            "IndexWeight": "0.003779456533874269"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Quest Diagnostics, Inc.",
            "IndexWeight": "0.0000715853377265125"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Raymond James Financial",
            "IndexWeight": "0.0003544763709310456"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Realty Income Corporation",
            "IndexWeight": "0.0006699582387424214"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Regency Centers Corporation",
            "IndexWeight": "0.00018137032458791588"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Regeneron Pharmaceuticals, Inc.",
            "IndexWeight": "0.002252895613555285"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Regions Financial Corporation",
            "IndexWeight": "0.00029524847583119364"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Republic Services, Inc. A",
            "IndexWeight": "0.0006250137457566674"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ResMed Inc Common Stock",
            "IndexWeight": "0.000587118604624695"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "WestRock Co Shs",
            "IndexWeight": "0.0002597692854462251"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Rockwell Automation Inc",
            "IndexWeight": "0.0008441611376697304"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Rollins, Inc.",
            "IndexWeight": "0.00021290209633837944"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Roper Technologies Inc Common Stock",
            "IndexWeight": "0.0009110559496029074"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ross Stores, Inc.",
            "IndexWeight": "0.0009385709637075791"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Royal Caribbean Group Ordinary Shares",
            "IndexWeight": "0.0004419712983830765"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SBA Communications Corporation Class A Shs",
            "IndexWeight": "0.00045168990500172527"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Pool Corp",
            "IndexWeight": "0.0003178652845031241"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Salesforce Inc",
            "IndexWeight": "0.004345809264181178"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Seagate Technology Holdings PLC",
            "IndexWeight": "0.00017789864726110911"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sempra",
            "IndexWeight": "0.0010853845435635375"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sherwin-Williams Company",
            "IndexWeight": "0.001747252619395085"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Simon Property Group, Inc.",
            "IndexWeight": "0.0007330758468111028"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sirius XM Holdings Inc.",
            "IndexWeight": "0.00006002290603886708"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Skyworks Solutions, Inc.",
            "IndexWeight": "0.0003922654406689788"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Snap-on, Inc.",
            "IndexWeight": "0.0003286324062138037"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Southern Copper Corporation",
            "IndexWeight": "0.00016585191782192364"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Southwest Airlines, Co.",
            "IndexWeight": "0.00027672434685246163"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Travelers Companies, Inc.",
            "IndexWeight": "0.0007212329485393787"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Stanley Black & Decker Common Stock",
            "IndexWeight": "0.00022034120536372536"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Starbucks Corporation",
            "IndexWeight": "0.0016999615752437897"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "State Street Corporation",
            "IndexWeight": "0.0004091611255776101"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Steel Dynamics, Inc.",
            "IndexWeight": "0.00032688327835297916"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Steris PLC Common Stock",
            "IndexWeight": "0.0005337006782509722"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Stryker Corporation",
            "IndexWeight": "0.0026313147101332144"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sun Communities Inc",
            "IndexWeight": "0.00026564115063281763"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Gen Digital Inc",
            "IndexWeight": "0.00022557749235575298"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Synopsys Inc",
            "IndexWeight": "0.001314772941299976"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sysco Corporation",
            "IndexWeight": "0.00013812400958697314"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "T. Rowe Price Group Inc Shs",
            "IndexWeight": "0.0004034770013681417"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "TJX Companies Inc Shs",
            "IndexWeight": "0.002341278777964449"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Take-Two Interactive Software, Inc.",
            "IndexWeight": "0.00043075036846488743"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Target Corporation",
            "IndexWeight": "0.0007073311525123881"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bio-Techne Corp",
            "IndexWeight": "0.0002843520757856895"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Teledyne Technologies Inc Common Stock",
            "IndexWeight": "0.0005023182089996057"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Teleflex Inc Common Stock",
            "IndexWeight": "0.00026898728943644594"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Teradyne, Inc.",
            "IndexWeight": "0.0004105704112095227"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Texas Instruments, Inc.",
            "IndexWeight": "0.003665926051052179"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Thermo Fisher Scientific, Inc.",
            "IndexWeight": "0.003309028106122215"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Globe Life Inc.",
            "IndexWeight": "0.00019225855558542305"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tractor Supply",
            "IndexWeight": "0.00017021805268745436"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Trimble Inc",
            "IndexWeight": "0.0003099124538950582"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tyler Technologies, Inc.",
            "IndexWeight": "0.0002891560833764845"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tyson Foods, Inc. A",
            "IndexWeight": "0.0003080285892745536"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "United Airlines Holdings Inc Common Stock",
            "IndexWeight": "0.0002952391169991695"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "U.S. Bancorp Shs",
            "IndexWeight": "0.0011031950931158403"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "UDR, Inc.",
            "IndexWeight": "0.0002028362702865121"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "United Parcel Service, Inc.",
            "IndexWeight": "0.0035327421742596516"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "United Rentals Inc Common Stock",
            "IndexWeight": "0.0004994269525672411"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Unitedhealth Group Inc",
            "IndexWeight": "0.00824324883287667"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Universal Health Services B",
            "IndexWeight": "0.00015183163706454936"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Vail Resorts, Inc.",
            "IndexWeight": "0.00012881920852660705"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ventas, Inc.",
            "IndexWeight": "0.0003810651360259001"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "VeriSign, Inc.",
            "IndexWeight": "0.00030160824421507343"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Verizon Communications Inc.",
            "IndexWeight": "0.002524990515401119"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Vertex Pharmaceuticals Inc",
            "IndexWeight": "0.0025827770046854947"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Vulcan Materials Company",
            "IndexWeight": "0.0006602162014921967"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "W.P. Carey Inc Shs",
            "IndexWeight": "0.00022923355576207817"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "W.R. Berkley Corporation",
            "IndexWeight": "0.0002460191973198486"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "W.W. Grainger, Inc.",
            "IndexWeight": "0.0004884957187390075"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Walmart Inc Shs",
            "IndexWeight": "0.004966119959350232"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Walgreens Boots Alliance Inc",
            "IndexWeight": "0.0003759847027766929"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Walt Disney Co Shs",
            "IndexWeight": "0.0027939619845670956"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Waste Management Inc Common Stock",
            "IndexWeight": "0.0012344197003253342"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Waters Corporation",
            "IndexWeight": "0.0003164999755391385"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Watsco, Inc. Class A Shs",
            "IndexWeight": "0.0003003425957649721"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Elevance Health Inc Common Stock",
            "IndexWeight": "0.0017663639062376712"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "West Pharmaceutical Services, Inc.",
            "IndexWeight": "0.0006186034281327736"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Western Digital Corporation",
            "IndexWeight": "0.00017764923724914537"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Westlake Corp",
            "IndexWeight": "0.0000610353382486764"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Weyerhaeuser Company",
            "IndexWeight": "0.00031596607478178216"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Willis Towers Watson PLC Shs",
            "IndexWeight": "0.00042531614597869795"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Xcel Energy, Inc.",
            "IndexWeight": "0.0006411808901568451"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Yum Brands, Inc.",
            "IndexWeight": "0.00029329675081395085"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Zebra Technologies Corporation",
            "IndexWeight": "0.0003163333973080391"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Zimmer Biomet Holdings Inc Shs",
            "IndexWeight": "0.0005940242874185004"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "eBay, Inc.",
            "IndexWeight": "0.00042575127233565255"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Brown-Forman Corporation A",
            "IndexWeight": "0.00007653737762384378"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Heico Corp Class A Shs",
            "IndexWeight": "0.0002581336665324844"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Chipotle Mexican Grill, Inc. A",
            "IndexWeight": "0.000903776987614725"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Transdigm Group, Inc.",
            "IndexWeight": "0.0013055229139843985"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lennar Corporation B",
            "IndexWeight": "0.00003749987247491853"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CCL Industries Inc. Non Voting Share",
            "IndexWeight": "0.00013620433852909415"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Canadian Tire Corp Non Voting Share",
            "IndexWeight": "0.00009609920062397471"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Canadian Utilities Ltd Ordinary Shares - Class A",
            "IndexWeight": "0.00002544226421877081"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Empire Company Limited Non Voting Share",
            "IndexWeight": "0.00000647335235964273"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Finning International Inc.",
            "IndexWeight": "0.00005683006949031386"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "National Bank of Canada",
            "IndexWeight": "0.00041806621968107447"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Power Corporation Of Canada Subordinate Voting Share",
            "IndexWeight": "0.0002624382030974147"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "George Weston Limited",
            "IndexWeight": "0.00015131655976986566"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "West Fraser Timber Co.Ltd",
            "IndexWeight": "0.00009261821009987713"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Loblaw Companies Limited",
            "IndexWeight": "0.000301522521321501"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "IGM Financial Inc.",
            "IndexWeight": "0.00003543849611995171"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Metro Inc Common Stock",
            "IndexWeight": "0.00025339101063644165"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Alimentation Couche-Tard Inc",
            "IndexWeight": "0.0005785001775030672"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Great-West Lifeco Inc.",
            "IndexWeight": "0.00015179448920062707"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "iA Financial Corp Inc Ordinary Shares",
            "IndexWeight": "0.00012094258478968567"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lundin Mining Corp",
            "IndexWeight": "0.00011843229178153584"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Intact Financial Corp Common Stock",
            "IndexWeight": "0.0004612246487835129"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mastercard Inc Incorporated A",
            "IndexWeight": "0.006024354132299278"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CGI Inc Shs A",
            "IndexWeight": "0.0003653494501251202"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "BCE Inc.",
            "IndexWeight": "0.00027649908329911157"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Agnico Eagle Mines Ltd",
            "IndexWeight": "0.0006351330496433277"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bank of Montreal",
            "IndexWeight": "0.0011461018835516425"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bank of Nova Scotia Ordinary Shares",
            "IndexWeight": "0.00101747518090434"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Barrick Gold Corp",
            "IndexWeight": "0.0006132062710971005"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CAE, Inc.",
            "IndexWeight": "0.00016326439564262631"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cameco Corporation",
            "IndexWeight": "0.00045521642405117453"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Canadian Imperial Bank of Commerce",
            "IndexWeight": "0.0007326454417057216"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fairfax Financial Holdings Ltd Subord Vtg Shs",
            "IndexWeight": "0.00038111083012697046"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fortis Inc Ordinary Shares",
            "IndexWeight": "0.00005561331087833293"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Gildan Activewear, Inc.",
            "IndexWeight": "0.0001304529343769339"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kinross Gold Corporation Ordinary Shares",
            "IndexWeight": "0.0001545741503752258"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Magna International Common Stock",
            "IndexWeight": "0.00023177159011772004"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Manulife Financial Corporation",
            "IndexWeight": "0.0006675059199120708"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Open Text Corporation",
            "IndexWeight": "0.00019353883034400467"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nutrien Ltd Shs",
            "IndexWeight": "0.00040160755434689587"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Quebecor, Inc. B",
            "IndexWeight": "0.0000592556863129921"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "RB Global Inc",
            "IndexWeight": "0.00018185373825804822"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Rogers Communications Inc Common Stock B",
            "IndexWeight": "0.00027487778676223753"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Royal Bank of Canada",
            "IndexWeight": "0.0023667157828197696"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Saputo, Inc.",
            "IndexWeight": "0.00001024099815096922"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Wheaton Precious Metals Corp Shs",
            "IndexWeight": "0.0005614844394230973"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sun Life Financial Inc Common Stock",
            "IndexWeight": "0.000509280665944061"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Telus Corporation Shs",
            "IndexWeight": "0.00013792275287032875"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Thomson Reuters Corp Common Stock",
            "IndexWeight": "0.00037400370379413834"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Toronto-Dominion Bank Common Stock",
            "IndexWeight": "0.0019946953774664026"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Owens-Corning, Inc.",
            "IndexWeight": "0.0002697027784607436"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Constellation Software Inc.",
            "IndexWeight": "0.000853895849128804"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "First Solar, Inc.",
            "IndexWeight": "0.00047963955519577886"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Air Canada B",
            "IndexWeight": "0.00006742047431029926"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ANZ Group Holdings Ltd",
            "IndexWeight": "0.000844691844731574"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ASX Limited Ordinary Shares",
            "IndexWeight": "0.00013919634507775835"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bluescope Steel Limited Ordinary Share",
            "IndexWeight": "0.00000187594610293175"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CSL Limited Ordinary Share",
            "IndexWeight": "0.0022838078824007836"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cochlear Limited Ordinary Share",
            "IndexWeight": "0.00030103604428266133"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Commonwealth Bank of Australia Ordinary Share",
            "IndexWeight": "0.002133670103668172"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Computershare Ltd Ordinary Share",
            "IndexWeight": "0.00016005015635163497"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fortescue Ltd",
            "IndexWeight": "0.00000529697897883169"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Incitec Pivot Limited Ordinary Share",
            "IndexWeight": "0.0000099192051486641"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "IGO Ltd Ordinary Share",
            "IndexWeight": "0.00011170232023185635"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Insurance Australia Group Ltd Ordinary Share",
            "IndexWeight": "0.00015688269751049585"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "James Hardie Industries PLC Chess Depository Interests",
            "IndexWeight": "0.00035108709828292594"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Macquarie Group Ltd Ordinary Shares",
            "IndexWeight": "0.0008135175960498836"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "National Australia Bank Limited Ordinary Share",
            "IndexWeight": "0.0010746383695458273"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "QBE Insurance Group Ltd",
            "IndexWeight": "0.00026130162095812327"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Qantas Airways Ltd",
            "IndexWeight": "0.00008654962511264547"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ramsay Health Care Limited Ordinary Share",
            "IndexWeight": "0.00010953777533653336"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Reece Ltd Shares",
            "IndexWeight": "0.00011070266214239533"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Rio Tinto Limited Ordinary Share",
            "IndexWeight": "0.00000575292572562668"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "TPG Telecom Ltd Ordinary Shares",
            "IndexWeight": "0.00002155682235729968"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Seek Limited Ordinary Share",
            "IndexWeight": "0.00009762867277933568"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sonic Healthcare Limited Ordinary Share",
            "IndexWeight": "0.00016402061114971926"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Suncorp Group Ltd Ordinary Share",
            "IndexWeight": "0.0002011905261985407"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Telstra Group Ltd",
            "IndexWeight": "0.0001771415245771433"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Transurban Group Ordinary Share",
            "IndexWeight": "0.0007606192272213216"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Wesfarmers Limited Ordinary Share",
            "IndexWeight": "0.0004990994091359286"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Westpac Banking Corporation Ordinary Share",
            "IndexWeight": "0.0009111677304496169"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Woolworths Group Ltd Ordinary Share",
            "IndexWeight": "0.0006042029924931274"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "REA Group Limited Ordinary Shares",
            "IndexWeight": "0.00010329392162910908"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mineral Resources Limited Ordinary Share",
            "IndexWeight": "0.00016158983062826196"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Northern Star Resources Ltd Ordinary Share",
            "IndexWeight": "0.00023925658615098954"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Admiral Grp",
            "IndexWeight": "0.00013576892486774823"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Anglo American PLC Common Stock",
            "IndexWeight": "0.00004689728499613481"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ashtead Grp",
            "IndexWeight": "0.0004973882776345852"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Associated British Foods",
            "IndexWeight": "0.00018349356072666482"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AstraZeneca PLC Common Stock",
            "IndexWeight": "0.005026340123782483"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Barclays PLC Common Stock",
            "IndexWeight": "0.000453271182695174"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Barratt Developments",
            "IndexWeight": "0.00010887106170632421"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "BT Group PLC",
            "IndexWeight": "0.00014176924284701843"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bunzl PLC",
            "IndexWeight": "0.0002167866833441977"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Burberry Group PLC Common Stock",
            "IndexWeight": "0.00014169483468282946"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Compass Group PLC Common Stock",
            "IndexWeight": "0.00038320738840327796"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "DCC",
            "IndexWeight": "0.00017163844620076064"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dechra Pharmaceuticals",
            "IndexWeight": "0.00013289109082907405"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Diageo PLC",
            "IndexWeight": "0.0019210925577913126"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "GSK PLC Common Stock",
            "IndexWeight": "0.001608071710630712"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Experian PLC Common Stock",
            "IndexWeight": "0.0005998782425420396"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Halma PLC",
            "IndexWeight": "0.0001837508407300854"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hikma Pharmaceuticals",
            "IndexWeight": "0.00007430472038597437"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hiscox Ltd Ordinary Shares",
            "IndexWeight": "0.00007416498467598103"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "HSBC Holdings PLC Common Stock",
            "IndexWeight": "0.0025201933174240393"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Intermediate Capital Grp",
            "IndexWeight": "0.00010267163647062334"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "JD Sports Fashion PLC Shs",
            "IndexWeight": "0.00008305352353845915"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kingfisher",
            "IndexWeight": "0.00005884625449463895"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Land Securities",
            "IndexWeight": "0.00010045767086146244"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Legal & General Group PLC Ordinary Shares",
            "IndexWeight": "0.0003146096134588813"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Melrose Industries PLC Shs",
            "IndexWeight": "0.00019795407707146235"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NEXT PLC",
            "IndexWeight": "0.00026619898001255265"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Pearson plc",
            "IndexWeight": "0.00013728616166234036"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Persimmon PLC",
            "IndexWeight": "0.00009124974844508803"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Prudential",
            "IndexWeight": "0.0004984018458069028"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "RELX PLC shares",
            "IndexWeight": "0.001245592677968653"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Rentokil Initial PLC",
            "IndexWeight": "0.00024141008588310362"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Rio Tinto PLC Registered Shares",
            "IndexWeight": "0.00001321351785518655"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sage Group PLC",
            "IndexWeight": "0.0002532608066355369"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Schroders PLC",
            "IndexWeight": "0.00007858139061630171"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SSE Plc.",
            "IndexWeight": "0.0011033769821305072"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Segro plc",
            "IndexWeight": "0.0002634883271273115"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Smith & Nephew PLC Common Stock",
            "IndexWeight": "0.0002802728286482959"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "St. James's Place Capital",
            "IndexWeight": "0.00007622523446462982"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Taylor Wimpey PLC Common Stock",
            "IndexWeight": "0.0001116280586215115"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tesco PLC Common Stock",
            "IndexWeight": "0.0005395213610594369"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Unilever PLC Common Stock",
            "IndexWeight": "0.002034468499878858"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "United Utilities Group PLC Class A Shs",
            "IndexWeight": "0.000232167730346439"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Vodafone Group PLC Common Stock",
            "IndexWeight": "0.00041416278060846083"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Contact Energy Limited Common Stock",
            "IndexWeight": "0.0001655749714646192"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Centrica PLC",
            "IndexWeight": "0.00011414043875124982"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Intercontinental Hotels Group PLC Common Stock",
            "IndexWeight": "0.00017665809759522257"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Howden Joinery Group Plc",
            "IndexWeight": "0.00011918218029573486"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Rightmove PLC",
            "IndexWeight": "0.0000963975647457496"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "abrdn PLC Shs",
            "IndexWeight": "0.00006721742075842912"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Severn Trent",
            "IndexWeight": "0.00021542087615472957"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "DS Smith PLC",
            "IndexWeight": "0.0001282856805780584"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Whitbread PLC Ordinary Shares",
            "IndexWeight": "0.00013377249287623906"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Canadian Apartment Properties Real Estate Investment Trust Trust Unit",
            "IndexWeight": "0.00004725295556785968"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Riocan Real Estate Investment Trust Trust Unit",
            "IndexWeight": "0.00004236405268039572"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "TFI International Inc Shs",
            "IndexWeight": "0.000405430147010779"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "WSP Global Inc Common Share",
            "IndexWeight": "0.00045702293018069366"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Broadridge Financial Solutions",
            "IndexWeight": "0.0003963203806856144"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "T-Mobile US Inc Shares",
            "IndexWeight": "0.0012260770510037979"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Insulet Corporation",
            "IndexWeight": "0.0003707821590283956"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Jazz Pharmaceuticals PLC Common Stock",
            "IndexWeight": "0.0001842721334973089"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "3i Group Ord",
            "IndexWeight": "0.0004904047724282794"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Reckitt Benckiser Group PLC Ordinary Shares",
            "IndexWeight": "0.0009887434384939922"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Croda International PLC Shs",
            "IndexWeight": "0.00017485701944898991"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NatWest Group PLC Common Stock",
            "IndexWeight": "0.00021659559215075075"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Standard Chartered.",
            "IndexWeight": "0.00020478681642916425"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "J Sainsbury",
            "IndexWeight": "0.00001331581835141671"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Antofagasta",
            "IndexWeight": "0.00015801422340680645"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Berkeley Group Holdings PLC Common Stock",
            "IndexWeight": "0.00014614150211945324"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lloyds TSB Grp",
            "IndexWeight": "0.0006002842599874837"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Aviva PLC Common Stock",
            "IndexWeight": "0.0002515205967388463"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "National Grid PLC",
            "IndexWeight": "0.0020104195491568765"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "London Stock Exchange",
            "IndexWeight": "0.0008609993591173262"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Blackstone Inc",
            "IndexWeight": "0.0015696714729242973"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Discover Financial Services",
            "IndexWeight": "0.0004362106109207795"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "TE Connectivity Ltd Common Share",
            "IndexWeight": "0.0009508224253319455"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lululemon Athletica, Inc",
            "IndexWeight": "0.0011908369566449536"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Amplifon",
            "IndexWeight": "0.00010278777728567243"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Buzzi SpA",
            "IndexWeight": "0.00000042868044219658"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Davide Campari-Milano NV Shs",
            "IndexWeight": "0.00013622646010733424"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Enel SpA Shares",
            "IndexWeight": "0.0015835790517988012"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Stellantis NV Shs",
            "IndexWeight": "0.0010126387435577349"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "UnipolSai SPA Shares",
            "IndexWeight": "0.0000082463596638039"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Assicurazioni Generali",
            "IndexWeight": "0.000359725804793758"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "HERA",
            "IndexWeight": "0.00000745451505961211"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Intesa Sanpaolo SpA Az",
            "IndexWeight": "0.0007807562111449066"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mediobanca Grp",
            "IndexWeight": "0.00013345608992365587"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Banca Mediolanum Shs",
            "IndexWeight": "0.00001995159179538199"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Prysmian SpA Shs",
            "IndexWeight": "0.0002577797534415885"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Recordati SpA Ord",
            "IndexWeight": "0.00012363884189956097"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Telecom Italia Az.",
            "IndexWeight": "0.00004000535178362794"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Telecom Italia Az.di Risp. non conv.",
            "IndexWeight": "0.00002474805835363936"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Terna SpA Shares",
            "IndexWeight": "0.0006255302972008377"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Unicredit Group",
            "IndexWeight": "0.0007749115006458333"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mercadolibre, Inc.",
            "IndexWeight": "0.00131644866864853"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Banco BPM SpA Shs",
            "IndexWeight": "0.00012160909897045792"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "American Water Works",
            "IndexWeight": "0.0006435335866659449"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Adidas AG",
            "IndexWeight": "0.0007762032584799084"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Allianz SE Namen-Akt. vinkuliert",
            "IndexWeight": "0.00174699833201819"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "BASF SE Akt (Common Stock)",
            "IndexWeight": "0.00013470144516810974"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Beiersdorf",
            "IndexWeight": "0.00034435969777996437"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Commerzbank AG",
            "IndexWeight": "0.0001984797621706704"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Continental",
            "IndexWeight": "0.00000151106929772125"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mercedes-Benz Group AG",
            "IndexWeight": "0.001346094823396619"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Deutsche Bank AG",
            "IndexWeight": "0.00041770797388352587"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Deutsche Boerse",
            "IndexWeight": "0.0006266957212503984"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Deutsche Lufthansa AG",
            "IndexWeight": "0.00009603456663093446"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "DHL Group",
            "IndexWeight": "0.0011063836109748255"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Deutsche Telekom",
            "IndexWeight": "0.0019243354124957443"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "E. On SE Aktiengesellschaft AG Namen Akt",
            "IndexWeight": "0.0003808024718365032"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fresenius SE & Co KGaA Common Share",
            "IndexWeight": "0.00019517784449028577"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hannover Rueck Namen Akt",
            "IndexWeight": "0.00024517130780294413"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Heidelberg Materials AG",
            "IndexWeight": "0.00000195359340745013"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Henkel KGaA",
            "IndexWeight": "0.00004337852590116636"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Infineon Technologies",
            "IndexWeight": "0.0012560951679825533"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Merck KGaA",
            "IndexWeight": "0.0005372499157594282"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "MTU Aero Engines AG",
            "IndexWeight": "0.0002751283542480777"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Münchener Rück AG",
            "IndexWeight": "0.0009569351691433467"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Puma SE",
            "IndexWeight": "0.00013041356670955153"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "RWE AG Class A shs",
            "IndexWeight": "0.0003558889937451451"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SAP SE Common Stock",
            "IndexWeight": "0.0027680292423343704"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Siemens AG",
            "IndexWeight": "0.0001372545537160596"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Symrise AG",
            "IndexWeight": "0.0003109173332836188"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Carl Zeiss Meditec",
            "IndexWeight": "0.00009235387082307008"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Qiagen NV",
            "IndexWeight": "0.00015994888344206842"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Deutsche Wohnen I Common Stock",
            "IndexWeight": "0.00002570926466120834"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bayerische Motoren Werke AG Common Stock",
            "IndexWeight": "0.0003994516879023142"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CK Hutchison Holdings Ltd Shs",
            "IndexWeight": "0.00018735932610233096"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Wharf (Holdings) Ltd Common Stock",
            "IndexWeight": "0.00005910688152167063"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Power Assets Holdings Ltd Common Stock",
            "IndexWeight": "0.00021968452487545085"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hang Seng Bank",
            "IndexWeight": "0.00014231668064928528"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Henderson Land Development",
            "IndexWeight": "0.00008226211720981871"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sun Hung Kai Properties",
            "IndexWeight": "0.0004146324065292816"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Swire Pacific",
            "IndexWeight": "0.00004462679669082198"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "MTR Corporation Ltd.",
            "IndexWeight": "0.0002288974802618148"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sino Land Co. Ltd.",
            "IndexWeight": "0.00007431336997735967"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Swire Pacific Ltd",
            "IndexWeight": "0.0000139768898267235"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hang Lung Properties Ltd",
            "IndexWeight": "0.00005938259427791206"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hong Kong Exchanges & Clearing Ltd Ordinary Shares",
            "IndexWeight": "0.0007446918165745882"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Techtronic Industries",
            "IndexWeight": "0.00036988314620015316"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cheung Kong Infrastructure Holdings",
            "IndexWeight": "0.0001026477651335257"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ulta Beauty Inc Rg shs",
            "IndexWeight": "0.00041177671955213303"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Aeroports de Paris SA Shs",
            "IndexWeight": "0.00015446579250744235"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AXA SA Common Share",
            "IndexWeight": "0.0009428690034548424"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "BioMerieux SA",
            "IndexWeight": "0.00007546617633029762"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "BNP Paribas Common Stock",
            "IndexWeight": "0.0011786056944476707"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "BOUYGUES Common Shares",
            "IndexWeight": "0.00011044650043868408"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Capgemini SE Shs",
            "IndexWeight": "0.0005517836443860001"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Carrefour",
            "IndexWeight": "0.00022008464467098186"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Crédit Agricole Common Share",
            "IndexWeight": "0.000287354623106937"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Danone",
            "IndexWeight": "0.0006948199330667445"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Eiffage",
            "IndexWeight": "0.00020585237953763802"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Essilorluxottica Ordinary Shares",
            "IndexWeight": "0.0014713603140106347"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Eurofins Scientific SE",
            "IndexWeight": "0.00013654249019004407"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Orange SA Act",
            "IndexWeight": "0.000337344861852351"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Engie SA Shs",
            "IndexWeight": "0.00026184650839010544"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Getlink SE Act Provenant Regroupement Common Stock",
            "IndexWeight": "0.00019910268149770524"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hermès International",
            "IndexWeight": "0.0015640373520274362"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ipsen Act",
            "IndexWeight": "0.00010429412642608023"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "L'Oreal S.A. Act",
            "IndexWeight": "0.002529279386374858"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lvmh Moet Hennessy Louis Vuitton SE",
            "IndexWeight": "0.004508051172740687"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Michelin Common Shares",
            "IndexWeight": "0.0005085352743956998"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kering SA",
            "IndexWeight": "0.0007468700305292343"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Publicis Groupe SA Act",
            "IndexWeight": "0.000350474459554054"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sanofi",
            "IndexWeight": "0.0026815748967610412"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Schneider Electric SE Common Stock",
            "IndexWeight": "0.001495821310328027"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Societe Generale SA Shs",
            "IndexWeight": "0.0003220104928274753"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sodexo Act",
            "IndexWeight": "0.00011720542578736325"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "STMicroelectronics NV.",
            "IndexWeight": "0.0007735459122611882"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Veolia Environnement",
            "IndexWeight": "0.00040280822735747654"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Vinci Common Shares",
            "IndexWeight": "0.0010621428149382225"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Vivendi SE Common Stock",
            "IndexWeight": "0.00011917724371085774"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Pernod Ricard Ordinary Shares",
            "IndexWeight": "0.000832796017387065"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "MSCI Inc Common Stock",
            "IndexWeight": "0.0007617153431519608"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dexus Shs",
            "IndexWeight": "0.00009187860861082865"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "GPT Group Stapled Security",
            "IndexWeight": "0.00009758286025363585"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mirvac Group",
            "IndexWeight": "0.0000929281003280959"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Stockland Corporation",
            "IndexWeight": "0.00012133851559579275"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sartorius Stedim Biotech",
            "IndexWeight": "0.00015820914719198216"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Goodman Group",
            "IndexWeight": "0.0005977877688000535"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Pilbara Minerals Ltd Shares",
            "IndexWeight": "0.000196480507511092"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Brambles Limited Ordinary Share",
            "IndexWeight": "0.00029262573329736036"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Iberdrola SA Common Stock",
            "IndexWeight": "0.0025726073167355186"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Swatch Group AG",
            "IndexWeight": "0.00006652161400422875"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Novo Nordisk A/S Common Stock B",
            "IndexWeight": "0.008912089651105157"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Holcim Ltd Shares",
            "IndexWeight": "0.00000691878870439771"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nestle SA Act Nom",
            "IndexWeight": "0.005634469168563595"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Schindler Holding Ltd",
            "IndexWeight": "0.00012414204410913216"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sika AG Registered Shares",
            "IndexWeight": "0.0011981986181472167"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Swatch Grp Ltd",
            "IndexWeight": "0.0001896241553309539"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sonova Holding AG Namen-Akt",
            "IndexWeight": "0.0003812789651278583"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kuehne + Nagel International AG Shares",
            "IndexWeight": "0.0004262951527785459"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Novartis Inc Namen-Akt Registered Shares",
            "IndexWeight": "0.004959934998523343"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Swiss Life Holding AG",
            "IndexWeight": "0.00032924106901116026"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Barry Callebaut",
            "IndexWeight": "0.00007483734549410196"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "UBS Group AG",
            "IndexWeight": "0.0016893793683537114"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Swisscom",
            "IndexWeight": "0.0002581131756637615"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Straumann Holding",
            "IndexWeight": "0.0004490997857444516"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ABB Ltd Namen Share",
            "IndexWeight": "0.0003845406728985473"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Geberit",
            "IndexWeight": "0.0004839538559040678"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Zurich Insur Gr N",
            "IndexWeight": "0.0011807904583046318"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Givaudan",
            "IndexWeight": "0.0008667384379219522"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lonza Group AG",
            "IndexWeight": "0.000491128228027232"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "EMS-Chemie Holding",
            "IndexWeight": "0.0001302452158209516"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Partners Group Holding",
            "IndexWeight": "0.0005244933474986732"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Banco Santander SA Reg Shs",
            "IndexWeight": "0.0010543238464173202"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Carlsberg A/S Share B",
            "IndexWeight": "0.00008382438349321993"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Coloplast A/S",
            "IndexWeight": "0.0003928263073685926"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Danske Bank A/S Shs",
            "IndexWeight": "0.00030184103221691394"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Demant AS Ordinary Shares",
            "IndexWeight": "0.00009895545401849885"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ackermans & Van Haaren NV",
            "IndexWeight": "0.00006216318167484941"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Colruyt Group NV",
            "IndexWeight": "0.00003404844852971729"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "D'Ieteren Group Shs",
            "IndexWeight": "0.00008565493407533149"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ageas NV Act",
            "IndexWeight": "0.00011059032137091021"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Groupe Bruxelles Lambert SA Shs",
            "IndexWeight": "0.00011361397733600272"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "KBC Groupe NV",
            "IndexWeight": "0.00028404660407279134"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sofina SA",
            "IndexWeight": "0.00005884990943620429"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Solvay",
            "IndexWeight": "0.00000282095720904742"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Umicore SA Act",
            "IndexWeight": "0.00007567888247758176"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "UCB",
            "IndexWeight": "0.00023332193410733305"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Elia Group SA/NV",
            "IndexWeight": "0.0001843377641403367"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Vestas Wind Systems",
            "IndexWeight": "0.0008031995708195884"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "DSV AS",
            "IndexWeight": "0.0008217483453290548"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Genmab A/S",
            "IndexWeight": "0.0005029892585753899"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Novozymes A/S Common Stock B",
            "IndexWeight": "0.00028314739807054493"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tryg A/S Shs",
            "IndexWeight": "0.00011533328456304"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "A. P. Moller Maersk A/S Shs B",
            "IndexWeight": "0.00004991060986793418"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "A. P. Moller Maersk A/S Shs A",
            "IndexWeight": "0.00003222965690558391"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Banco Bilbao Vizcaya Argentaria SA Ordinary Shares",
            "IndexWeight": "0.0008640456403927959"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Acciona,S.A.",
            "IndexWeight": "0.00008279464633252019"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Endesa SA Commoon Stock",
            "IndexWeight": "0.00014608310762624838"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Naturgy Energy Group SA Ordinary Shares",
            "IndexWeight": "0.00000345858046060938"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Telefonica S.A. Common Stock",
            "IndexWeight": "0.0003220497483297453"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Redeia Corporacion SA",
            "IndexWeight": "0.000335522197315833"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Industria de Diseno Textil Inditex SA Common Stock",
            "IndexWeight": "0.0009702164102889938"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ferrovial SE",
            "IndexWeight": "0.00039373088528979266"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Stora Enso OYJ.",
            "IndexWeight": "0.00021243566405382158"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Huhtamäki Oyj",
            "IndexWeight": "0.00004659946608215297"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kesko Oyj Common stock B",
            "IndexWeight": "0.00005641079075980289"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nokia Corporation Common Stock",
            "IndexWeight": "0.00007910108320132838"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "UPM-Kymmene Corporation Common Stock",
            "IndexWeight": "0.000428551061805251"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fortum Oyj",
            "IndexWeight": "0.00000103528463733696"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kesko Oyj Common stock A",
            "IndexWeight": "0.00001910298880474431"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "KONE Oyj",
            "IndexWeight": "0.00023211649043497582"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Elisa Oyj",
            "IndexWeight": "0.00010829162913007438"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ASM International NV Common Stock",
            "IndexWeight": "0.0005807010423142265"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Akzo Nobel NV Common Stock",
            "IndexWeight": "0.00015901511782133584"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Heineken N.V.",
            "IndexWeight": "0.000585565367387045"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AB InBev Rg",
            "IndexWeight": "0.0012645502001110056"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ING Groep N.V. Cert of Shs",
            "IndexWeight": "0.000820190039166655"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Koninklijke KPN NV Shs",
            "IndexWeight": "0.00025352769472115216"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Koninklijke Philips NV Shs",
            "IndexWeight": "0.0004491009780707582"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Wolters Kluwer",
            "IndexWeight": "0.0005685076021279492"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Koninklijke Ahold Delhaize NV Shs",
            "IndexWeight": "0.00004477452363690619"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hal Trust",
            "IndexWeight": "0.00007377970420772927"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Randstad NV Shs",
            "IndexWeight": "0.00011207524674448147"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Heineken Holding",
            "IndexWeight": "0.0002064831226185953"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Jeronimo Martins SGPS SA Register Shs",
            "IndexWeight": "0.0000319278382769976"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "EDP-Energias de Portugal SA",
            "IndexWeight": "0.0005383656762122718"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "City Developments Ltd Common Stock",
            "IndexWeight": "0.00004872183068420841"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "DBS Group Holdings, Ltd.",
            "IndexWeight": "0.0007894561469621437"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Keppel Corporation Limited",
            "IndexWeight": "0.00010399669921856064"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Oversea-Chinese Banking",
            "IndexWeight": "0.0005395833334535922"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Singapore Airlines Ltd Common Stock",
            "IndexWeight": "0.00023343670846394808"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "United Overseas Bank Ltd",
            "IndexWeight": "0.0004878397554913094"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Great Eastern Holdings Insurance, Ltd.",
            "IndexWeight": "0.00001290197798756864"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Singapore Telecommunications Ltd",
            "IndexWeight": "0.00019863235078464294"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Singapore Technologies Engineering Ltd",
            "IndexWeight": "0.00007706894305157902"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "UOL Group Limited",
            "IndexWeight": "0.00005058951407196392"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CapitaLand Ascendas REIT Units",
            "IndexWeight": "0.00014145668633915249"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CapitaLand Integrated Commercial Trust",
            "IndexWeight": "0.00018057468646771746"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Atlas Copco",
            "IndexWeight": "0.0010408237919140874"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hexagon AB",
            "IndexWeight": "0.000598895045681433"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Telefonaktiebolaget L M Ericsson Shs B",
            "IndexWeight": "0.00009751747630661659"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Telefonaktiebolaget L M Ericsson Shs",
            "IndexWeight": "0.00000174392567446017"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hennes & Mauritz AB",
            "IndexWeight": "0.0001823539686293416"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Industrivärden AB C",
            "IndexWeight": "0.00008235613973828172"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Industrivärden AB A",
            "IndexWeight": "0.00007060763926589212"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Investor A.B. B",
            "IndexWeight": "0.0006865100945156315"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SKF, Group A",
            "IndexWeight": "0.00000555702530120681"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SKF, Group B",
            "IndexWeight": "0.00014341723559684757"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SSAB AB Shs-A",
            "IndexWeight": "0.00000415926352393649"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sandvik AB Common Stock",
            "IndexWeight": "0.00045124398100141"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Securitas AB",
            "IndexWeight": "0.00008678326521582157"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Skandinaviska Enskilda Banken",
            "IndexWeight": "0.0003670929561201342"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Skanska AB",
            "IndexWeight": "0.00011573364559461929"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Svenska Cellulosa AB Class A Shs",
            "IndexWeight": "0.00001495910477902052"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Svenska Cellulosa AB SCA B",
            "IndexWeight": "0.00047200189113453254"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Svenska Handelsbanken A",
            "IndexWeight": "0.0002642900541991894"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Svenska Handelsbanken B",
            "IndexWeight": "0.00000739139087797997"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Trelleborg",
            "IndexWeight": "0.00016070311413120215"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Volvo Corporation B",
            "IndexWeight": "0.00000633239599516649"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Assa Abloy AB Common Stock B",
            "IndexWeight": "0.00046466022586749256"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Getinge AB",
            "IndexWeight": "0.00011354480962856327"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "L E Lundbergforetagen AB Shs",
            "IndexWeight": "0.00009829931495070771"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tele2 AB B",
            "IndexWeight": "0.0000718788749712941"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Castellum AB",
            "IndexWeight": "0.00011962989895148807"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nordea Bank Abp Common Stock",
            "IndexWeight": "0.0006915342193365065"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SAAB AB",
            "IndexWeight": "0.00009206196602317604"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fastighets AB Balder",
            "IndexWeight": "0.00008836452009666007"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nibe Industrier",
            "IndexWeight": "0.0002290725654933857"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Investment AB Latour B",
            "IndexWeight": "0.00005953734960796598"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Axfood AB",
            "IndexWeight": "0.0000683120385607829"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Telia Company AB Shs",
            "IndexWeight": "0.00009241609208716762"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Swedbank AB Ordinary Shares A",
            "IndexWeight": "0.00031231110335057795"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Boliden AB",
            "IndexWeight": "0.00016075264478866987"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Alfa Laval AB Common Stock",
            "IndexWeight": "0.00028110753114575233"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Holmen AB B",
            "IndexWeight": "0.0000771150325036053"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Indutrade AB Shs",
            "IndexWeight": "0.00013801804003563177"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Volvo Corporation A",
            "IndexWeight": "0.00000081639061055318"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SSAB AB Shs-B",
            "IndexWeight": "0.00001035776830914012"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Investor A.B.",
            "IndexWeight": "0.0002125774333160645"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sampo Oyj",
            "IndexWeight": "0.0003423812592911016"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Skandinaviska Enskilda Banken Stock C",
            "IndexWeight": "0.00000456696881126732"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Aegon Ltd",
            "IndexWeight": "0.00004254474178042579"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Metso Corp Ordinary Shares",
            "IndexWeight": "0.00012332916247321819"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Roche Holding Ltd Bearer Shares",
            "IndexWeight": "0.00020312840146842015"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Orion Corp",
            "IndexWeight": "0.00011487808659824489"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Husqvarna AB Ordinary Shares Class B",
            "IndexWeight": "0.00002749475831866971"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Atlas Copco B",
            "IndexWeight": "0.0005530347132978642"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Swedish Orphan Biovitrum AB Common Stock",
            "IndexWeight": "0.0001397773357227249"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Wilmar International Ltd",
            "IndexWeight": "0.00006057396287975055"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Orion Corp. A Shares",
            "IndexWeight": "0.00002905212998096198"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Chocoladefabriken Lindt & Spruengli Ordinary Shares",
            "IndexWeight": "0.0002814012564318341"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Advantest Corporation",
            "IndexWeight": "0.0005841323293649183"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Aeon Co Ltd Common Stock",
            "IndexWeight": "0.0003504010190670741"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ajinomoto Ordinary Shares",
            "IndexWeight": "0.0002553704752192468"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ANA HOLDINGS INC",
            "IndexWeight": "0.00009438160777301385"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Asahi Group Holdings Ltd Common Stock",
            "IndexWeight": "0.00035973630193626395"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AGC Inc Ordinary Shares",
            "IndexWeight": "0.00003015781940880337"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Asahi Kaisei Corp Shs",
            "IndexWeight": "0.00008730116879835833"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Astellas Pharma Inc Common Stock",
            "IndexWeight": "0.0005167121482312832"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bridgestone",
            "IndexWeight": "0.00000527249411810614"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Canon, Inc. Common Stock",
            "IndexWeight": "0.00048357952289276114"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Chubu Electric Power",
            "IndexWeight": "0.00019504796943033115"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Chugai Pharmaceutical",
            "IndexWeight": "0.0005569583276570774"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sumitomo Mitsui Trust Holdings Inc Common Share",
            "IndexWeight": "0.00023275247983019107"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dai Nippon Printing Co Ltd",
            "IndexWeight": "0.00008868948296758176"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Daiichi Sankyo Co Ltd Common Stock",
            "IndexWeight": "0.0012037125039844894"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Daiwa House Industry Co Ltd Common Stock",
            "IndexWeight": "0.0003240651780564186"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Daiwa Securities Group Inc Common Stock",
            "IndexWeight": "0.00015703788267812185"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Denso Corporation",
            "IndexWeight": "0.0004648260746601603"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dentsu Group Inc Ordinary Shares",
            "IndexWeight": "0.00009832949485519939"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "East Japan Railway",
            "IndexWeight": "0.0007211025353265527"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Eisai Co Ltd",
            "IndexWeight": "0.00032785229595407207"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fanuc Corp Common Stock",
            "IndexWeight": "0.0003286639661643882"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fast Retailing",
            "IndexWeight": "0.0008879059020660059"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fuji Electric Co., Ltd.",
            "IndexWeight": "0.00000103975495948067"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Subaru Corp Shs",
            "IndexWeight": "0.00003130426488057715"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "FUJIFILM Hldgs",
            "IndexWeight": "0.00037391182866141395"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fujitsu Limited",
            "IndexWeight": "0.00036322206534116896"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hitachi, Ltd",
            "IndexWeight": "0.00046076873601166943"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Honda Motor",
            "IndexWeight": "0.0000784904839669562"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Isuzu Motors Ltd Common Stock",
            "IndexWeight": "0.00002400101305251031"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "JFE Holdings, Inc.",
            "IndexWeight": "0.00000419313593799195"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Japan Airlines Co Ltd Common Stock",
            "IndexWeight": "0.00007818723320903966"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "KDDI Corp.",
            "IndexWeight": "0.0007396286789964241"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kajima Corp Shs",
            "IndexWeight": "0.00016368040165134066"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kao Corporation",
            "IndexWeight": "0.00025507897328375415"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kawasaki Heavy Industries, Ltd",
            "IndexWeight": "0.0000006235797318019"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Keio Corp.",
            "IndexWeight": "0.00004454569229683579"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Keisei Electric Railway",
            "IndexWeight": "0.00017560966760368186"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kikkoman Corp.",
            "IndexWeight": "0.00025563967762527506"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kirin Holdings Co Ltd Shs",
            "IndexWeight": "0.00022852515767713045"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Komatsu Ltd",
            "IndexWeight": "0.00031869499343280396"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kubota Corporation",
            "IndexWeight": "0.0001739960072402078"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kyocera Corporation",
            "IndexWeight": "0.00025694577313026387"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kyowa Kirin Co Ltd Shs",
            "IndexWeight": "0.00010072730840058851"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Panasonic Holdings Corp Common Shares",
            "IndexWeight": "0.00001265296601156278"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mazda Motor Corp Common Stock",
            "IndexWeight": "0.00000267762070339133"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Meiji Holdings Co Ltd Common Stock",
            "IndexWeight": "0.00010104234337552597"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tokio Marine Holdings Inc Common Stock",
            "IndexWeight": "0.0008078648360430002"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "MINEBEA MITSUMI Inc shs",
            "IndexWeight": "0.00013672814521493247"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mitsubishi Chemical Group Corp",
            "IndexWeight": "0.00001168085127611052"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mitsubishi",
            "IndexWeight": "0.0009492090821357656"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mitsubishi Electric",
            "IndexWeight": "0.00000498557021516763"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mitsubishi Estate Co Ltd Shr",
            "IndexWeight": "0.0003286337254806167"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mitsubishi Heavy Industries, Ltd",
            "IndexWeight": "0.0004055984219213237"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mitsubishi Motors",
            "IndexWeight": "0.00000197355186824359"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mitsubishi Tokyo Fin. Grp",
            "IndexWeight": "0.0017491250048090369"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mitsui Chemicals",
            "IndexWeight": "0.00003154989859252743"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mitsui Fudosan",
            "IndexWeight": "0.00041529577559493006"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mizuho Financial Grp",
            "IndexWeight": "0.0007446916007395535"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NEC Corporation",
            "IndexWeight": "0.0001901222701994966"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NGK Insulators, Ltd",
            "IndexWeight": "0.00009590824291723206"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NTT Data Group Corp Common Stock",
            "IndexWeight": "0.0001375024255960452"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nikon Corp",
            "IndexWeight": "0.00007016241671035308"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nippon Telegraph & Telephone Corp Ordinary Shares",
            "IndexWeight": "0.0005123299261288709"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nissan Chemical Corp Common Stock",
            "IndexWeight": "0.00010854334439769468"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nissan Motor Co Ltd",
            "IndexWeight": "0.00001955062867841931"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nisshin Seifun Grp",
            "IndexWeight": "0.00000060109279014501"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nomura Holdings, Inc.",
            "IndexWeight": "0.00022708925344535916"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Obayashi Corp Shs",
            "IndexWeight": "0.00011845324737590813"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Odakyu Electric Railway",
            "IndexWeight": "0.00009389861159445362"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Oji Holdings Corp",
            "IndexWeight": "0.00007955453119015926"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Olympus Corporation",
            "IndexWeight": "0.0004174129772208909"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Osaka Gas Co., Ltd.",
            "IndexWeight": "0.00004828799047037151"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Resona Holdings, Inc.",
            "IndexWeight": "0.00019010825511096691"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ricoh Co Ltd Shs",
            "IndexWeight": "0.00009356340741739687"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sumco Corp",
            "IndexWeight": "0.0001103253216473452"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SECOM Co Ltd Common Stock",
            "IndexWeight": "0.00022956253851494582"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sekisui House, Ltd",
            "IndexWeight": "0.0002758072502246074"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Seven & I Holdings",
            "IndexWeight": "0.00026923084264344034"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sharp Corporation",
            "IndexWeight": "0.00000027518211490175"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Shimizu Corp.",
            "IndexWeight": "0.0000325934248663488"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Shin-Etsu Chemical",
            "IndexWeight": "0.0016579707420855366"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Shionogi & Co Ltd",
            "IndexWeight": "0.00033608711956691794"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Shiseido Company, Ltd.",
            "IndexWeight": "0.0002603746047420032"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SoftBank Group Corp shs",
            "IndexWeight": "0.0007395936454536957"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sony Group Corp Common Stock",
            "IndexWeight": "0.002475638210477044"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sumitomo Chemical",
            "IndexWeight": "0.00005116460417148885"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sumitomo Electric Industries Ltd Common Stock",
            "IndexWeight": "0.000150186537454488"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sumitomo Metal Mining",
            "IndexWeight": "0.0000711069720878341"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sumitomo Mitsui Financial Grp",
            "IndexWeight": "0.001100997566575372"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sumitomo Realty & Development Co Ltd Common Stock",
            "IndexWeight": "0.00026209368206868585"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Suzuki Motor",
            "IndexWeight": "0.00002346757257052747"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "T&D Holdings",
            "IndexWeight": "0.0001350115535188025"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "TDK Corporation",
            "IndexWeight": "0.00018379396204182204"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Toto Ltd Shs",
            "IndexWeight": "0.00000070698729542219"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Taisei Corp",
            "IndexWeight": "0.00015865845964998937"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Takeda Pharmaceutical Co., Ltd.",
            "IndexWeight": "0.001045409793367421"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Terumo Corp.",
            "IndexWeight": "0.0005346088680996566"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Chiba Bank Ltd",
            "IndexWeight": "0.00008636853901191043"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Kansai Electric Power Shs",
            "IndexWeight": "0.0001086521855488451"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tobu Railway",
            "IndexWeight": "0.00015610690478499344"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Toho Co Ltd Common Stock",
            "IndexWeight": "0.00006149290396943755"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tokyo Electron Ltd",
            "IndexWeight": "0.0014868760593628742"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tokyo Gas Co., Ltd.",
            "IndexWeight": "0.00004108569915903849"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tokyu Corp.",
            "IndexWeight": "0.00011505190576089846"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Toppan Inc shs",
            "IndexWeight": "0.00006869076775476483"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Toray Industries, Inc. Common Stock",
            "IndexWeight": "0.00014601780605000544"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tosoh Corp.",
            "IndexWeight": "0.00000652347582824048"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Toyota Motor Ordinary Shares",
            "IndexWeight": "0.0026014457014194216"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Toyota Tsusho",
            "IndexWeight": "0.00021905225093992172"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Trend Micro",
            "IndexWeight": "0.00010267606930649088"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "West Japan Railway",
            "IndexWeight": "0.00016513129600678544"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "LY Corporation",
            "IndexWeight": "0.00014862485130355578"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Yamaha Corp.",
            "IndexWeight": "0.00006989737686717161"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Yamato Holdings Co., Ltd.",
            "IndexWeight": "0.0001569286341997428"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Yokogawa Electric",
            "IndexWeight": "0.00010399889265263688"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Chocoladefabriken Lindt & Spruengli AG Shs",
            "IndexWeight": "0.00027392308027181076"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Aker ASA A Share",
            "IndexWeight": "0.00002782858272502047"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ASML Holding NV Common Stock Bearer Form",
            "IndexWeight": "0.0068769155636838174"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kerry Group PLC",
            "IndexWeight": "0.0001826024806414272"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "DNB Bank ASA Common Share",
            "IndexWeight": "0.0003054880354084913"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Auckland International Airport Ltd Ordinary Share",
            "IndexWeight": "0.00028113015197741615"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Henkel KGaA (Preference)",
            "IndexWeight": "0.00008275452044864565"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CaixaBank SA Common Stock",
            "IndexWeight": "0.00028186000694391494"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Roche Holding AG",
            "IndexWeight": "0.0045455685028463165"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fisher & Paykel Healthcare Corporation Limited Ordinary Share",
            "IndexWeight": "0.00018555059535756323"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Schindler Hldg Partizipsch",
            "IndexWeight": "0.0002582276718189842"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Telenor ASA",
            "IndexWeight": "0.00009724946776520265"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Smurfit Kappa Group PLC Common Stock",
            "IndexWeight": "0.00025075573194007546"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bank Leumi",
            "IndexWeight": "0.00020336232329367512"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Yara International",
            "IndexWeight": "0.00000101103021033274"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bank Hapoalim B.M.",
            "IndexWeight": "0.00019464738269585365"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bezeq Israel Telecom",
            "IndexWeight": "0.0000430705351019106"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Schibsted ASA A Shares -A-",
            "IndexWeight": "0.00003352401407234328"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NICE",
            "IndexWeight": "0.00020571530154335523"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "United Mizrahi Bank Ltd",
            "IndexWeight": "0.00009729059712023574"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kingspan Group PLC",
            "IndexWeight": "0.0002846140784934927"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Spark New Zealand Ltd Common Stock",
            "IndexWeight": "0.00014701442279908505"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ferguson PLC Shs",
            "IndexWeight": "0.0008448911436340048"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mowi ASA Common Stock",
            "IndexWeight": "0.0004008743565922522"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SalMar ASA",
            "IndexWeight": "0.00020717201451848018"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Elbit Systems Ltd Common Stock",
            "IndexWeight": "0.00012172737027062277"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Orkla ASA Common Share",
            "IndexWeight": "0.00011628864345325159"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Israel Discount Bank Ltd",
            "IndexWeight": "0.00010409160644219802"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mondi PLC",
            "IndexWeight": "0.00023086011098496001"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "HARGREAVES LANSDOW",
            "IndexWeight": "0.00005472850634711586"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Norsk Hydro ASA Common Stock",
            "IndexWeight": "0.00003323561022307222"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Volkswagen AG Non Voting",
            "IndexWeight": "0.00014186051517975037"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Link Real Estate Investment Trust",
            "IndexWeight": "0.0002433704837373689"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Storebrand ASA Shs",
            "IndexWeight": "0.00006471461898801818"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Visa, Inc.",
            "IndexWeight": "0.006996366815329294"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ING Bank Slaski S.A. Common Stock",
            "IndexWeight": "0.00003278419357069683"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "BMW VZ",
            "IndexWeight": "0.00007275634700010739"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SARTORIUS VZ",
            "IndexWeight": "0.0002239952373768965"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "LPP S.A.",
            "IndexWeight": "0.00008400974041249198"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bureau Veritas",
            "IndexWeight": "0.0001223592823977367"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Zozo Inc Shs",
            "IndexWeight": "0.00004572115701957318"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "MS&AD Insurance Group Holdings Inc Common Stock",
            "IndexWeight": "0.00028141429524358345"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "TIS Inc Shs",
            "IndexWeight": "0.00008263701621931657"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "LEGRAND SA",
            "IndexWeight": "0.0005853329264038001"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Voest-Alpine Stahl",
            "IndexWeight": "0.00000118067106101639"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "EVN AG",
            "IndexWeight": "0.0000099001872216445"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Verbund AG Class A",
            "IndexWeight": "0.0003183667399239461"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Erste Group Bank AG Shs",
            "IndexWeight": "0.0002455281772673667"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Andritz AG",
            "IndexWeight": "0.00005659136225222675"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Telekom Austria",
            "IndexWeight": "0.00001828054727833838"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Raiffeisen Bank International AG share",
            "IndexWeight": "0.00005355568316667096"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Vienna Insurance Group AG Ordinary Shares",
            "IndexWeight": "0.00001786376242801197"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Strabag SE",
            "IndexWeight": "0.00001248744314478042"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Beijer Ref AB Common Stock",
            "IndexWeight": "0.00011251860004467338"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ROHM",
            "IndexWeight": "0.00013701840886703968"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Yakult Honsha",
            "IndexWeight": "0.00014392094878870837"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nidec",
            "IndexWeight": "0.00047672921425284795"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Capcom",
            "IndexWeight": "0.00007945695514597862"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Acom Shs",
            "IndexWeight": "0.00001899492558711486"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Misumi",
            "IndexWeight": "0.00010858505075705175"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hikari Tsushin",
            "IndexWeight": "0.0000611658500879198"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Oriental Land",
            "IndexWeight": "0.0006609859469144456"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Pan Pacific International Holdings Corp",
            "IndexWeight": "0.000189196762140443"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "KGHM Polska Miedz SA Common Stock",
            "IndexWeight": "0.00001927947647336003"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Zensho Holdings Co.Ltd.",
            "IndexWeight": "0.00004643689821529095"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Asics Corp Common Stock",
            "IndexWeight": "0.0001355045130602808"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Brother Industries, Ltd",
            "IndexWeight": "0.00005101366520328081"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Aisin Corp",
            "IndexWeight": "0.0000587067868247896"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Daifuku Co Ltd",
            "IndexWeight": "0.00012483356146643463"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kurita Water Industries Ltd Shs",
            "IndexWeight": "0.0000953639874607901"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Central Japan Railway",
            "IndexWeight": "0.0007678231554595385"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hankyu Hldgs",
            "IndexWeight": "0.00009703336283961204"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hamamatsu Photonics K.K.",
            "IndexWeight": "0.00013900415780151694"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Koito Manufacturing",
            "IndexWeight": "0.00007465551340298848"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "OMRON Corp",
            "IndexWeight": "0.00005633293346824964"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Keyence",
            "IndexWeight": "0.002044866370377705"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Shimadzu",
            "IndexWeight": "0.00018635052082421617"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ibiden Co Ltd Common Stock",
            "IndexWeight": "0.00016670278280070698"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kansai Paint",
            "IndexWeight": "0.00007601205446483498"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nintendo",
            "IndexWeight": "0.0008824891275070837"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mitsubishi HC Capital Inc",
            "IndexWeight": "0.00009930653559079163"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nitto Denko",
            "IndexWeight": "0.0002317232647122356"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nippon Paint Holdings Co Ltd Common Stock",
            "IndexWeight": "0.00017056727595310476"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nissin Food Products",
            "IndexWeight": "0.0001351145816483286"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Toyo Suisan Kaisha Ltd shs",
            "IndexWeight": "0.0000353188680663949"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Toyota Industries",
            "IndexWeight": "0.00011054830695754287"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SMC Shs",
            "IndexWeight": "0.000754223070332473"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sekisui Chemical",
            "IndexWeight": "0.00005143531039122879"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Yamaha Motor",
            "IndexWeight": "0.00001675809270845826"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kyoto Financial Group Inc",
            "IndexWeight": "0.00007466117062069906"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Daito Trust Construction Co Ltd Common Stock",
            "IndexWeight": "0.00010832461921967754"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Murata Mfg Co. Ltd 100",
            "IndexWeight": "0.0007977645027149176"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Niterra Co Ltd Ordinary Shares",
            "IndexWeight": "0.00010217375314493037"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Disco",
            "IndexWeight": "0.0005575232305589887"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Yaskawa Electric",
            "IndexWeight": "0.00004437772391802074"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hoya Corp Common Stock",
            "IndexWeight": "0.0010625674578340974"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kintetsu Group Holdings Co Ltd Shs",
            "IndexWeight": "0.00009373833002322997"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hitachi Construction Machinery",
            "IndexWeight": "0.00000866047678027647"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SCSK Corporation",
            "IndexWeight": "0.00004569512701036712"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nitori Holdings Co Ltd",
            "IndexWeight": "0.0001829381271912797"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Shimano",
            "IndexWeight": "0.00020727634304049727"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Medipal Holdings Corp Common Stock",
            "IndexWeight": "0.0000728491708625389"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nippon Sanso Holdings Corp",
            "IndexWeight": "0.00007731636154513902"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ORIX Shs",
            "IndexWeight": "0.00035329537953806964"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ono Pharmaceutical Co Ltd Shs",
            "IndexWeight": "0.0001950938947640107"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Taisho Pharmaceutical Holdings Co Ltd",
            "IndexWeight": "0.00007514005941009491"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "USS",
            "IndexWeight": "0.0000857340057919178"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Japan Airport Terminal",
            "IndexWeight": "0.0001322752452520062"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sysmex Corp Common Stock",
            "IndexWeight": "0.00023971903458762348"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bank Polska Kasa Opieki S.A.",
            "IndexWeight": "0.00013080665342911555"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Oracle Corp. Japan",
            "IndexWeight": "0.00004198053560890075"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Rakuten Group Inc",
            "IndexWeight": "0.00013851649762265455"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lawson Inc Common Stock",
            "IndexWeight": "0.00003742714409665937"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "KOSE Corp Common Stock",
            "IndexWeight": "0.00005434518774730643"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ABC-Mart",
            "IndexWeight": "0.00003692600901755637"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Santander Bank Polska SA",
            "IndexWeight": "0.00006869095467377622"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "McDonald's Hldgs Company (Japan), Ltd",
            "IndexWeight": "0.00005504325115128694"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nippon Building Fund",
            "IndexWeight": "0.0001491885240927678"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Otsuka",
            "IndexWeight": "0.00006891920785527127"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nomura Research Institute, Ltd",
            "IndexWeight": "0.0002035779857686534"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Japan Real Estate Investment Shs",
            "IndexWeight": "0.00011515844296876773"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SBI Holdings Inc Common Stock",
            "IndexWeight": "0.00010224282472951836"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Renesas Electronics Corp Common Stock",
            "IndexWeight": "0.0005554323657030226"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tokyo Century Corp",
            "IndexWeight": "0.00002902201450260811"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "M3, Inc.",
            "IndexWeight": "0.0001322052570156604"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Seiko Epson Corp. Common Stock",
            "IndexWeight": "0.00007247468748236639"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bandai Namco Holdings Inc. Common Stock",
            "IndexWeight": "0.00031631473523661844"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "COSMOS Pharmaceutical",
            "IndexWeight": "0.00005446472424988209"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nomura Real Estate Hldgs,",
            "IndexWeight": "0.00005168801059780991"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fukuoka Financial Group Inc Common Stock",
            "IndexWeight": "0.00007405033675402268"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Daiwa House REIT Investment Corp Common Stock",
            "IndexWeight": "0.00006441115468917091"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "KOBE BUSSAN",
            "IndexWeight": "0.00006055218850560143"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "MatsukiyoCocokara & Co",
            "IndexWeight": "0.0001396879310442929"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Monotaro Co Ltd",
            "IndexWeight": "0.00005753316337484025"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "JSR",
            "IndexWeight": "0.0001141269249153047"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "OBIC",
            "IndexWeight": "0.00017862710277738005"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Haseko",
            "IndexWeight": "0.00003251000257007798"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hirose Electric",
            "IndexWeight": "0.00007816690980824381"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hulic Co Ltd Common Stock",
            "IndexWeight": "0.00011281993857047235"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Unicharm",
            "IndexWeight": "0.000322861195887244"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Square Enix",
            "IndexWeight": "0.00004411878699992879"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Makita Corp.",
            "IndexWeight": "0.00007698679912545575"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Japan Metropolitan Fund Investment Corp Shs",
            "IndexWeight": "0.00010544382675492082"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Asahi Intecc",
            "IndexWeight": "0.00011726315049341992"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "PKO Bank Polski",
            "IndexWeight": "0.00017795215746403939"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Keurig Dr Pepper Inc Shs",
            "IndexWeight": "0.0006568532740341102"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fresnillo PLC",
            "IndexWeight": "0.00002792835310271213"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Diasorin SpA",
            "IndexWeight": "0.00003495363510647392"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "EDP Renovaveis",
            "IndexWeight": "0.00028231486451514825"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Welcia Holdings Co Ltd",
            "IndexWeight": "0.00003240077881482265"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Reinsurance Group of America, Inc",
            "IndexWeight": "0.00017425438511617654"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "WPP PLC Common Stock",
            "IndexWeight": "0.00016926470340107347"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Porsche Automobil Holding SE Preference Bearer Share",
            "IndexWeight": "0.0001844705320225269"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Compagnie Financiere Richemont SA Class A",
            "IndexWeight": "0.0015501950711346547"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Persol Holdings Co Ltd Shs",
            "IndexWeight": "0.00005864994866607824"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hoshizaki Corp Common Stock",
            "IndexWeight": "0.00007961595240288234"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Informa PLC Common Stock",
            "IndexWeight": "0.00020657511296557165"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Broadcom Inc Shares",
            "IndexWeight": "0.014524045187983463"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fortinet Inc.",
            "IndexWeight": "0.0006503396741448106"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Verisk Analytics, Inc.",
            "IndexWeight": "0.0005998176105689439"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Koei Tecmo Holdings Co Ltd Common Stock",
            "IndexWeight": "0.00002276785399001725"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dollarama Inc Common Stock",
            "IndexWeight": "0.000438184420884447"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dollar General Common Stock",
            "IndexWeight": "0.0005738432538189858"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Phoenix Group Holdings PLC Shs",
            "IndexWeight": "0.00008358908491210614"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lear Corporation Common Stock",
            "IndexWeight": "0.00001032390701114536"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Charter Communications Inc Common Stock A",
            "IndexWeight": "0.0007226639981984261"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Julius Baer Gruppe AG Namen-Akt",
            "IndexWeight": "0.00018328727531128914"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Atlas Arteria Ltd Shs",
            "IndexWeight": "0.00008982388556946483"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SS&C Technologies Holdings Inc",
            "IndexWeight": "0.00021819777771987968"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Sompo Holdings Inc Common Stock",
            "IndexWeight": "0.00024786503905006245"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dai-ichi Life Holdings Inc",
            "IndexWeight": "0.0003292045840348238"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Brenntag SE Common Stock",
            "IndexWeight": "0.00003564539142977001"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Seven Group Holdings Limited",
            "IndexWeight": "0.00005778008507853538"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Singapore Exchange Ltd Common Share",
            "IndexWeight": "0.00010164653088743275"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Powszechny Zaklad Ubezpieczen SA Common Shares",
            "IndexWeight": "0.00010773817390645214"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Chr. Hansen Holding A/S Shs",
            "IndexWeight": "0.0002123711507669071"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tesla Inc Common Stock",
            "IndexWeight": "0.01737214727062286"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Edenred SE Act",
            "IndexWeight": "0.00024364155977678713"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "KKR & Co Inc Ordinary Share",
            "IndexWeight": "0.0008927302111027385"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Amadeus IT Group SA Shs",
            "IndexWeight": "0.0005087774925255962"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ocado Group PLC Common Stock",
            "IndexWeight": "0.0001505744844720655"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NXP Semiconductors NV Common Stock",
            "IndexWeight": "0.0014200709550538158"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Pandora A/S Shs",
            "IndexWeight": "0.0002521961888815532"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AIA Group Common Share",
            "IndexWeight": "0.0017854770114193387"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "General Motors Co Common Stock",
            "IndexWeight": "0.00014614625042118094"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Booz Allen Hamilton Holding Corp Common Stock A",
            "IndexWeight": "0.00025359006806126343"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "LPL Financial Holdings Inc Common Stock",
            "IndexWeight": "0.0002967514043327461"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Gjensidige Forsikring ASA Common Stock",
            "IndexWeight": "0.00005900815268241997"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "FleetCor Technologies Inc Common Stock",
            "IndexWeight": "0.0003098502141586465"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Scentre Group Stapled Security",
            "IndexWeight": "0.00021210366239124366"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Otsuka Holdings Co Ltd Common Stock",
            "IndexWeight": "0.0003640671339892272"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "HCA Healthcare Inc Common Stock",
            "IndexWeight": "0.0008852694618510932"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Iida Group Holdings Co Ltd. Common Stock",
            "IndexWeight": "0.00002467117541565925"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Apollo Global Management Inc Common Stock A",
            "IndexWeight": "0.0006337746635642476"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mapletree Pan Asia Commercial Trust Reg S",
            "IndexWeight": "0.00007101473521627274"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Treasury Wine Estates Ltd Common Stock",
            "IndexWeight": "0.00006618919882318902"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Swiss Re AG Common Stock",
            "IndexWeight": "0.0005740274423059466"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mosaic Common Stock",
            "IndexWeight": "0.00011866690885388608"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Azrieli Group Common Stock",
            "IndexWeight": "0.0000476729632646578"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Zillow Group Inc Common Stock",
            "IndexWeight": "0.0000292205912559814"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Xylem Inc Common Stock",
            "IndexWeight": "0.0006878366438842881"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Aptiv PLC Shs",
            "IndexWeight": "0.00040845137477698364"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "HKT Ltd Stapled security",
            "IndexWeight": "0.00006884064525940817"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Vicinity Centres Stapled Security",
            "IndexWeight": "0.0001270922203694087"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NEXON Co Ltd Common Stock",
            "IndexWeight": "0.00013487558493914339"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Swire Properties Ltd Common Stock",
            "IndexWeight": "0.00005246113169722824"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "EPAM Systems Inc Common Stock",
            "IndexWeight": "0.0002869162536370562"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lasertec",
            "IndexWeight": "0.00047354900294112177"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Enphase Energy Inc Common Stock",
            "IndexWeight": "0.0004190515109615542"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Splunk Inc Common Stock",
            "IndexWeight": "0.0003833034819901342"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Carlyle Group Inc",
            "IndexWeight": "0.00013570614373724824"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Meta Platforms Inc Common Stock A",
            "IndexWeight": "0.01352566940625438"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ServiceNow Inc",
            "IndexWeight": "0.0023171007614214297"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Warehouses De Pauw SA",
            "IndexWeight": "0.00008166181299128706"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Palo Alto Networks Inc",
            "IndexWeight": "0.0015926066064101832"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Pentair PLC Common Stock",
            "IndexWeight": "0.00029397202897378836"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "TMX Group Ltd Common Stock",
            "IndexWeight": "0.0001057750489969217"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Talanx AG Common Stock",
            "IndexWeight": "0.0000726608891964216"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Workday Inc",
            "IndexWeight": "0.0009811282213606815"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ivanhoe Mines Ltd Shs -A- When Issued",
            "IndexWeight": "0.0001186747189508641"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AbbVie Inc",
            "IndexWeight": "0.0063614111421538685"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "GLP J-REIT Common Stock",
            "IndexWeight": "0.00009989438844080021"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Japan Exchange Group Inc Shs",
            "IndexWeight": "0.0001880871159637812"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Liberty Media Corp Registered Shs Series -A- Formula One",
            "IndexWeight": "0.00002295518382000508"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Zoetis Inc",
            "IndexWeight": "0.0021466961252420663"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nippon Prologis REIT. INC. Common Stock",
            "IndexWeight": "0.00012108046818199751"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AB Sagax Shs -B-",
            "IndexWeight": "0.00010461740707229654"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Spirax-Sarco Engineering PLC Shs",
            "IndexWeight": "0.0002376903681105927"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "IQVIA Holdings Inc Shs",
            "IndexWeight": "0.0007058805751215848"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Coca-Cola HBC Ltd",
            "IndexWeight": "0.000109240055549526"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Mercury NZ Ltd Shs",
            "IndexWeight": "0.0001534041412091951"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nomura Real Estate Master Fund, Inc. Shs",
            "IndexWeight": "0.00010256782478348166"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CDW Corp",
            "IndexWeight": "0.0005109847031108935"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "News Corp Common Stock B",
            "IndexWeight": "0.00004015619466930949"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "News Corp Common Stock A",
            "IndexWeight": "0.00013917873639043897"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Suntory Beverage & Food Limited Shs",
            "IndexWeight": "0.00006242364007448736"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Evonik Industries AG",
            "IndexWeight": "0.00001008643920300107"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Burlington Stores Inc Shs",
            "IndexWeight": "0.0002455214340157089"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CNH Industrial N.V. Shs",
            "IndexWeight": "0.00000255143065914247"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Tokyu Fudosan Holdings Corp Shs",
            "IndexWeight": "0.00006601978135615631"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Veeva Systems Inc Shs -A-",
            "IndexWeight": "0.0004623842297775278"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Open House Group Co Ltd Shs",
            "IndexWeight": "0.00004010173922550365"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Meridian Energy Ltd Shs 2013-15.5.15 Instalment Receipts",
            "IndexWeight": "0.00022237521935827362"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Allegion PLC",
            "IndexWeight": "0.0001828527457534957"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hilton Worldwide Holdings Inc Shs",
            "IndexWeight": "0.0006480499361502148"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Moncler S.p.A.",
            "IndexWeight": "0.00030254334895395965"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Vonovia SE Shares",
            "IndexWeight": "0.0004938561923611343"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Alphabet Inc Class C",
            "IndexWeight": "0.011937501717227694"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Paylocity Holding Corp Shs",
            "IndexWeight": "0.0001228527996689076"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Paycom Software Inc Shs",
            "IndexWeight": "0.00016874667395878144"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ares Management Corp Ordinary Shares Class A",
            "IndexWeight": "0.00034736305857201204"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Arista Networks Inc Shs",
            "IndexWeight": "0.0014838957050662682"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "B&M European Value Retail SA. Reg. shs",
            "IndexWeight": "0.00011895807245373916"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "FinecoBank S.P.A",
            "IndexWeight": "0.0001529755686124238"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "NN Group NV Shs",
            "IndexWeight": "0.00018211908962816268"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "argenx SE Shs",
            "IndexWeight": "0.0005310968093230916"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Liberty Media Corporation Series C Formula One Group Common Stock",
            "IndexWeight": "0.00022516992143058233"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Synchrony Financial Shs",
            "IndexWeight": "0.00023882156839869235"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "WH Group Ltd Shs",
            "IndexWeight": "0.00004084841711315411"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Citizens Financial Group",
            "IndexWeight": "0.00023560014423913123"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "HubSpot Inc Shs",
            "IndexWeight": "0.0004679974101580142"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Recruit Holdings Co Ltd Shs",
            "IndexWeight": "0.0011218493605395735"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Keysight Technologies Inc",
            "IndexWeight": "0.000661576628804179"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Liberty Broadband Corp Shs Series -A-",
            "IndexWeight": "0.0000253506496041331"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Medibank Private Ltd",
            "IndexWeight": "0.00011141787710162823"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Liberty Broadband Corp Shs Series -C-",
            "IndexWeight": "0.000168842095830842"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lifco Ab Shs -B-",
            "IndexWeight": "0.00008777443066507124"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Restaurant Brands International Inc Shs",
            "IndexWeight": "0.00018660915307972824"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Qorvo Inc Shs",
            "IndexWeight": "0.00025502260801276013"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Aena SME SA Shs",
            "IndexWeight": "0.00048706740537414006"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Auto Trader Group PLC Shs",
            "IndexWeight": "0.00013948213290897703"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "GoDaddy Inc Shs -A-",
            "IndexWeight": "0.00022573966609777858"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Etsy Inc Shs",
            "IndexWeight": "0.00019590323379126538"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cellnex Telecom S.A. Shs",
            "IndexWeight": "0.0004585046543778171"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "South32 Ltd Shs",
            "IndexWeight": "0.00000172007604728758"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Shopify Inc Shs -A- Subord.Voting",
            "IndexWeight": "0.0015692753961844637"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CK Asset Holdings Ltd Shs",
            "IndexWeight": "0.00020047410144097562"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "FirstService Corp Ordinary Shares",
            "IndexWeight": "0.0001244261011020542"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Schibsted Asa Shs -B-",
            "IndexWeight": "0.00004190116775477513"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "TransUnion Shs",
            "IndexWeight": "0.00020575739047866886"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Infrastrutture Wireless Italiane S.p.A.",
            "IndexWeight": "0.00008894377532703844"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "PayPal Holdings Inc Shs",
            "IndexWeight": "0.0010769460861414783"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Kraft Heinz Company Shs",
            "IndexWeight": "0.0003460418503894592"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Zillow Group Inc Shs -C-",
            "IndexWeight": "0.00009240773455941743"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hewlett Packard Enterprise Co Shares",
            "IndexWeight": "0.0004872000446214608"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Japan Post Holdings Co Ltd Shares",
            "IndexWeight": "0.00030956491188249797"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Japan Post Insurance Co Ltd Shares",
            "IndexWeight": "0.00005968779726235108"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Japan Post Bank Co Ltd Shares",
            "IndexWeight": "0.00023995928847975897"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Poste Italiane SPA",
            "IndexWeight": "0.00008713595919823718"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Amundi Group SA",
            "IndexWeight": "0.00007081862282365074"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Hydro One Ltd Shs",
            "IndexWeight": "0.0004885707988228074"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Block Inc Common Stock",
            "IndexWeight": "0.0006682539606769786"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "IDP Education ltd Shs",
            "IndexWeight": "0.00006160936053593775"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Atlassian Corp",
            "IndexWeight": "0.0005782216676011047"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ferrari NV Shs",
            "IndexWeight": "0.0009720655795937615"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Avangrid Inc Shs",
            "IndexWeight": "0.00004514375761823459"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Covestro AG Shares",
            "IndexWeight": "0.00012179311333793617"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Concordia Financial Group Ltd Shs",
            "IndexWeight": "0.00008734516456574972"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "WiseTech Global Ltd Shs",
            "IndexWeight": "0.0001475587000486443"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Liberty SiriusXM Group Shs Series A",
            "IndexWeight": "0.00003864680427247155"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Liberty SiriusXM Group Shs Series C",
            "IndexWeight": "0.00007903008460101648"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Orsted Shs",
            "IndexWeight": "0.00030313246246277425"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Waste Connections, Inc.",
            "IndexWeight": "0.0007633935365366606"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fortive Corp Shs",
            "IndexWeight": "0.0006189928545633406"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Twilio Inc Shs -A-",
            "IndexWeight": "0.00020370597361407634"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Trade Desk Inc Shs -A-",
            "IndexWeight": "0.0005567847943886733"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lamb Weston Holdings Inc Registered Shs",
            "IndexWeight": "0.00035823634412683527"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Kyushu Railway Company Shs",
            "IndexWeight": "0.00009222930563386293"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ConvaTec Group Limited Registered Shs",
            "IndexWeight": "0.00012435152023115847"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AB Sagax Shs -D-",
            "IndexWeight": "0.00000618499795937853"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Howmet Aerospace Inc Registered Shs",
            "IndexWeight": "0.0004716855563205109"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Invitation Homes Inc Registered REIT Shs",
            "IndexWeight": "0.0003436629341529241"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Snap Inc Registered Shs -A-",
            "IndexWeight": "0.00027060109191741956"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Okta Inc Registered Shs -A-",
            "IndexWeight": "0.00021586218748528138"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ingersoll Rand Inc Ordinary Shares",
            "IndexWeight": "0.00000491674845631353"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dino Polska SA Bearer Shs",
            "IndexWeight": "0.00008631283870058795"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "WuXi Biologics (Cayman) Inc. Registered Shs Unitary 144A/Reg S",
            "IndexWeight": "0.00048084852940856107"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Essity AB Registered Shs -A-",
            "IndexWeight": "0.0000112623124542588"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Essity AB Registered Shs -B-",
            "IndexWeight": "0.0003129919654332588"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Delivery Hero SE Shs",
            "IndexWeight": "0.00008368003741796723"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Pirelli & C. S.p.A. Ordinary Shares",
            "IndexWeight": "0.00003389686948915396"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "MongoDB Inc Shs",
            "IndexWeight": "0.0004900121331299929"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "VICI Properties Inc Shs",
            "IndexWeight": "0.000542114523474089"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bawag Group AG Ordinary Shares",
            "IndexWeight": "0.00007351142889838464"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Wharf Real Estate Investment Co Ltd Ordinary Shares",
            "IndexWeight": "0.00010507671055382476"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SG Holdings Co Ltd Ordinary Shares",
            "IndexWeight": "0.00018343781728922913"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Zscaler Inc shs",
            "IndexWeight": "0.0003386536505917586"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Siemens Healthineers AG Registered Shares",
            "IndexWeight": "0.0003730587012274804"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Ceridian HCM Holding Inc. Ordinary Shares",
            "IndexWeight": "0.00017439295070153772"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "DocuSign Inc Ordinary Shares",
            "IndexWeight": "0.00018437561346756843"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Equitable Holdings Inc Shs",
            "IndexWeight": "0.00017181509819179356"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Adyen NV Ordinary Shares",
            "IndexWeight": "0.0006218109983452523"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Epiroc AB Ordinary Shares Class A",
            "IndexWeight": "0.00023590584616538265"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Epiroc AB Ordinary Shares - Class B",
            "IndexWeight": "0.00013303948944319634"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "SoftBank Corp",
            "IndexWeight": "0.0005624417089575784"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Coles Group Ltd Shs",
            "IndexWeight": "0.00029398489173632926"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Moderna Inc Shs",
            "IndexWeight": "0.000754341352659923"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dell Technologies Inc Ordinary Shares - Class C",
            "IndexWeight": "0.0003679973381523263"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fox Corp Ordinary Shares Class A",
            "IndexWeight": "0.00012443343131110637"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Fox Corp Ordinary Shares Class B",
            "IndexWeight": "0.00006280795085665831"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dow Inc Shs",
            "IndexWeight": "0.00008257352817138142"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Pinterest Inc Ordinary Shares Cls A",
            "IndexWeight": "0.00034818393921349724"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Alcon Inc Registered Shares",
            "IndexWeight": "0.0009339106646008153"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Zoom Video Communications Inc Ordinary Shares Cls A",
            "IndexWeight": "0.00027075137266650867"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Adevinta ASA Class A",
            "IndexWeight": "0.00005127134795826212"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nexi SpA Ordinary Shares",
            "IndexWeight": "0.00007643753711789332"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Uber Technologies Inc",
            "IndexWeight": "0.0016835894742026983"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Avantor Inc Shs",
            "IndexWeight": "0.0003563709248905941"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Corteva Inc Ordinary Shares",
            "IndexWeight": "0.0007093145241622431"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Crowdstrike Holdings Inc Ordinary Shares - Class A",
            "IndexWeight": "0.0009645705535613655"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "ESR Group Ltd",
            "IndexWeight": "0.00007114194339421265"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Amcor PLC Ordinary Shares",
            "IndexWeight": "0.0002013056305738809"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Budweiser Brewing Co APAC Ltd Ordinary Shares",
            "IndexWeight": "0.00006487357313133239"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dynatrace Inc Shs",
            "IndexWeight": "0.00020201017444899038"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Prosus N.V. Ordinary Shares - Class N",
            "IndexWeight": "0.000750593077906294"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Cloudflare Inc Shs",
            "IndexWeight": "0.00041701966420060334"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Datadog Inc shs A",
            "IndexWeight": "0.0005678002537281766"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "EQT AB Ordinary Shares",
            "IndexWeight": "0.00031749925674102254"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "M&G Plc Ordinary Shares",
            "IndexWeight": "0.00010775848498576516"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "BILL Holdings Inc Ordinary Shares",
            "IndexWeight": "0.0001287894173678578"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "GFL Environmental Inc Shs",
            "IndexWeight": "0.0001102015336651871"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Carrier Global Corp",
            "IndexWeight": "0.0009923919380858813"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Otis Worldwide Corp",
            "IndexWeight": "0.0008665197127958351"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "JDE Peets NV Ordinary Shares",
            "IndexWeight": "0.00006357805826294604"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Royalty Pharma PLC Class A",
            "IndexWeight": "0.00024991460540640747"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Albertsons Companies Inc Class A",
            "IndexWeight": "0.00013893044490824553"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Brookfield Renewable Corp",
            "IndexWeight": "0.00020369346984804018"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Snowflake Inc Ordinary Shares - Class A",
            "IndexWeight": "0.0010450100596670398"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Unity Software Inc Ordinary Shares",
            "IndexWeight": "0.00015491955292468165"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Palantir Technologies Inc Ordinary Shares - Class A",
            "IndexWeight": "0.0006268780811081546"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Bentley Systems Inc Ordinary Shares - Class B",
            "IndexWeight": "0.0001985901889029532"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Siemens Energy AG Ordinary Shares",
            "IndexWeight": "0.00000106147511128812"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Allegro.EU Societe Anonyme Ordinary Shares",
            "IndexWeight": "0.00009060492462518379"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nordnet AB (publ)",
            "IndexWeight": "0.00004079197589096225"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "DoorDash Inc Ordinary Shares - Class A",
            "IndexWeight": "0.00044522253766552533"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Airbnb Inc Ordinary Shares - Class A",
            "IndexWeight": "0.0008773321852557797"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Roblox Corp Ordinary Shares - Class A",
            "IndexWeight": "0.00036233630358514503"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Coupang Inc Ordinary Shares - Class A",
            "IndexWeight": "0.00040898276625341365"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Coinbase Global Inc Ordinary Shares - Class A",
            "IndexWeight": "0.0004645382807625454"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Endeavour Group Ltd Ordinary Shares",
            "IndexWeight": "0.00008082403775402763"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Corporacion Acciona Energias Renovables SA",
            "IndexWeight": "0.00009674837084833517"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Wise PLC Class A",
            "IndexWeight": "0.0001223074649868712"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Lucid Group Inc",
            "IndexWeight": "0.00008760597116145721"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Azelis Group NV",
            "IndexWeight": "0.00003606225995745971"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Universal Music Group NV",
            "IndexWeight": "0.000445304075313829"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "CapitaLand Investment Ltd",
            "IndexWeight": "0.00011144324914928723"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "AutoStore Holdings Ltd Ordinary Shares",
            "IndexWeight": "0.00002884985752004181"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Volvo Car AB Class B",
            "IndexWeight": "0.00000172425274556807"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Rivian Automotive Inc Class A",
            "IndexWeight": "0.00036261124957611486"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Nippon Express Holdings Inc",
            "IndexWeight": "0.0001460195474185588"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Daimler Truck Holding AG Ordinary Shares",
            "IndexWeight": "0.0002913882569204693"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Constellation Energy Corp",
            "IndexWeight": "0.0001630700125141914"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "The Lottery Corp Ltd",
            "IndexWeight": "0.0001223180568207302"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Haleon PLC",
            "IndexWeight": "0.00023652375675265225"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "EXOR NV Shs",
            "IndexWeight": "0.00024050572042081726"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Corebridge Financial Inc",
            "IndexWeight": "0.00005629186082935677"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Dr. Ing. h.c. F. Porsche AG Bearer Shares",
            "IndexWeight": "0.00009973172903162764"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "Brookfield Asset Management Ltd Ordinary Shares - Class A",
            "IndexWeight": "0.0002295718619267525"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "GE HealthCare Technologies Inc",
            "IndexWeight": "0.00047481822419256475"
        },
        {
            "PortfolioId": "2843688",
            "PortfolioName": "Morningstar Developed Markets Target Market Exposure Paris Aligned Benchmark Select",
            "EffectiveDate": "2023-12-29",
            "ConstituentName": "DSM Firmenich AG Ordinary Shares",
            "IndexWeight": "0.0003944767635285208"
        }
    ];
}

export default getData;