import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import getData from './Data';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

export default function BasicTable() {
    const [investment, setInvestment] = useState(10000);

    function createData() {
        let data = getData();
        data.sort((a, b) => b.IndexWeight - a.IndexWeight);

        let rows = [];
        data.forEach(element => {
            let row = {
                name: element.ConstituentName,
                percent: new Intl.NumberFormat('sv-SE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(element.IndexWeight), // Format percentage to two decimals
                value: new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(element.IndexWeight * investment),
            }
            rows.push(row);
        });

        return rows;
    }

    const rows = createData();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ borderBottom: 'none' }}>
                            <b>LF Global index</b>
                        </TableCell>
                        <TableCell align="left" style={{ borderBottom: 'none' }}>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                        <TextField
                                value={investment}
                                onChange={(event) => setInvestment(event.target.value)}
                                size='small'
                                label="Investering"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kr</InputAdornment>,
                                }}
                            />
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <b>{row.name}</b> <br />
                                {row.percent}
                            </TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
